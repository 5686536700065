import React, { useRef, useState, useEffect, useCallback } from "react";
import { api } from "../../system/api";
import useUserInfo from "../../system/useUserInfo";
import { Typography, Tag, Input } from "antd";
import { Table, Modal, Drawer, Space, Form, Button, Select, DatePicker, InputNumber, } from "antd";
import { ExclamationCircleFilled, UserOutlined, DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
const { confirm } = Modal;
const { Text } = Typography;

export default function Loan() {
    const { userinfo } = useUserInfo();
    const [griddata, setGridData] = useState();
    const [exceldata, setexceldata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formdata] = Form.useForm();
    const [loanpurpose, setloanpurpose] = useState([]);

    const fetchData = useCallback(() => {
        setLoading(true);
        let coachid = (isNaN(userinfo.coachid) ? 0 : userinfo.coachid) * 1;
        let districtid = (isNaN(userinfo.districtid) ? 0 : userinfo.districtid) * 1;
        api
            .get(`/api/record/coach/get_loan_list?coachid=${coachid}&districtid=${districtid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let tdata = res?.data?.retdata.filter(row => row.isactive === true);
                    setGridData(tdata);
                    let list = [];
                    for (var i = 0; i < tdata.length; i++) {
                        let b = tdata[i];
                        let item = {};
                        item["Коуч"] = b.coachid;
                        item["Зээл авсан огноо"] = b.loandate;
                        item["Өрхийн дугаар"] = b.householdid;
                        item["Дүүрэг"] = b.districtname;
                        item["Хороо"] = b.section;
                        item["Өрхийн гол оролцогч гишүүний нэр"] = b.householdname;
                        item["Бүлгээс зээлсэн мөнгөн дүн"] = b.amount;
                        item["Зээлийн зориулалт"] = b.loanpurpose;
                        item["Зээлийн зориулалтын тайлбар"] = b.loanpurposenote;

                        list.push(item);
                    }
                    setexceldata(list);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [userinfo]);


    useEffect(() => {
        api.get(`/api/record/base/get_dropdown_item_list?type=loanpurpose`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setloanpurpose(res?.data?.retdata);
                }
            });
        fetchData();
    }, [fetchData]);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const gridcolumns = [
        {
            title: "Коуч",
            dataIndex: "coachid",
            ...getColumnSearchProps("coachid"),
            sorter: (a, b) => a.coachid - b.coachid,
        },
        {
            title: "Зээл авсан огноо",
            dataIndex: "loandate",
            ...getColumnSearchProps("loandate"),
            width: 200,
            sorter: (a, b) => a.loandate - b.loandate,
        },
        {
            title: "Өрхийн дугаар",
            dataIndex: "householdid",
            ...getColumnSearchProps("householdid"),
            sorter: (a, b) => a.householdid - b.householdid,
        },
        {
            title: "Дүүрэг",
            dataIndex: "districtname",
            ...getColumnSearchProps("districtname"),
            sorter: (a, b) => a.districtname - b.districtname,
        },
        {
            title: "Хороо",
            dataIndex: "section",
            ...getColumnSearchProps("section"),
            sorter: (a, b) => a.section - b.section,
        },
        {
            title: "Өрхийн гол оролцогч гишүүний нэр",
            dataIndex: "householdname",
            ...getColumnSearchProps("householdname"),
            sorter: (a, b) => a.householdname - b.householdname,
        },
        {
            title: "Бүлгээс зээлсэн мөнгөн дүн",
            dataIndex: "amount",
            ...getColumnSearchProps("amount"),
            align: "right",
            sorter: (a, b) => a.amount - b.amount,
        },
        {
            title: "Зээлийн зориулалт",
            dataIndex: "loanpurpose",
            ...getColumnSearchProps("loanpurpose"),
            sorter: (a, b) => a.loanpurpose - b.loanpurpose,
        },
        {
            title: "Зээлийн зориулалтын тайлбар",
            dataIndex: "loanpurposenote",
            ...getColumnSearchProps("loanpurposenote"),
            sorter: (a, b) => a.loanpurposenote - b.loanpurposenote,
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDeleteConfirm = () => {
        confirm({
            title: "Устгах уу?",
            icon: <ExclamationCircleFilled />,
            //content: 'Some descriptions',
            okText: "Тийм",
            okType: "danger",
            cancelText: "Үгүй",
            onOk() {
                onDelete();
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    };

    const onDelete = async () => {
        await api
            .delete(
                `/api/record/coach/delete_loan?id=${formdata.getFieldValue("entryid")}`
            )
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const onFinish = async (values) => {
        let fdata = formdata.getFieldsValue();
        fdata.loandate = fdata.loandate.format('YYYY.MM.DD HH:mm:ss');
        await api
            .post(`/api/record/coach/set_loan`, fdata)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const getFormData = async (entryid) => {
        await api.get(`/api/record/coach/get_loan?id=${entryid}`).then((res) => {
            if (res?.status === 200 && res?.data?.rettype === 0) {
                let fdata = res?.data?.retdata[0];
                fdata.loandate = dayjs(fdata.loandate, 'YYYY.MM.DD HH:mm:ss');
                formdata.setFieldsValue(fdata);
                showModal();
            }
        });
    };

    const tableOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                getFormData(record.entryid);
            },
        };
    };

    return (
        <div>

            <Table
                size="small"
                bordered
                title={() => (
                    <>
                        <Tag icon={<UserOutlined />} color="magenta">
                            Зээлийн мэдээлэл <b>{exceldata.length}</b> харагдаж байна.
                        </Tag>

                        <CSVLink data={exceldata} filename={"Зээлийн жагсаалт.csv"}>
                            <Button type="primary" icon={<DownloadOutlined />} size="small">
                                Татах
                            </Button>
                        </CSVLink>
                    </>
                )}
                onChange={(pagination, filters, sorter, extra) =>
                    setexceldata(extra.currentDataSource)
                }
                loading={loading}
                columns={gridcolumns}
                dataSource={griddata}
                onRow={tableOnRow}
                pagination={true}
                rowKey={(record) => record.entryid}
                summary={(pageData) => {
                    let totalamount = 0;
                    pageData.forEach(({ amount }) => {
                        totalamount += parseFloat(amount.replaceAll(",", ""));
                    });
                    totalamount = totalamount
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                    return (
                        <>
                            <Table.Summary.Row style={{ background: "#fafafa" }}>
                                <Table.Summary.Cell index={0}>Нийт:</Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} />
                                <Table.Summary.Cell index={3} />
                                <Table.Summary.Cell index={4} />
                                <Table.Summary.Cell index={5} />
                                <Table.Summary.Cell index={6} align="right">
                                    <Text>{totalamount}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={7} />
                                <Table.Summary.Cell index={8} />
                            </Table.Summary.Row>
                        </>
                    );
                }}
            ></Table>

            <Drawer
                forceRender
                title="Зээлийн мэдээлэл нэмэх"
                open={isModalOpen}
                width={720}
                onClose={handleCancel}
                bodyStyle={{ paddingBottom: 80, }}
                extra={
                    <Space>
                        <Button
                            key="delete"
                            danger
                            onClick={showDeleteConfirm}
                            hidden={formdata.getFieldValue("entryid") === 0 || userinfo.roleid === "4"}
                        >
                            Устгах
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Болих
                        </Button>
                        <Button
                            key="save"
                            type="primary"
                            onClick={() => { formdata.submit() }}
                            hidden={userinfo.roleid === "4"}
                        >
                            Хадгалах
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={formdata}
                    onFinish={onFinish}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    labelAlign="left"
                    labelWrap
                >
                    <Form.Item name="loandate" label="Зээл авсан огноо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <DatePicker style={{ width: "100%" }} placeholder="Өдөр сонгох" />
                    </Form.Item>
                    <Form.Item name="householdid" label="householdid" hidden={true}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="amount" label="Бүлгээс зээлсэн мөнгөн дүн" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <InputNumber
                            style={{ width: "100%" }}
                            placeholder="Мөнгөн дүн"
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                    <Form.Item name="loanpurposeid" label="Зээлийн зориулалт" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: '100%' }}>
                            {loanpurpose?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="loanpurposenote" label="Зээлийн зориулалтын тайлбар" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}
