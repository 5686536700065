import React from "react";

export default function Dashboard() {
    return (
        <div>
            <iframe
                title="Report Section"
                width="100%"
                height="800"
                src="https://app.powerbi.com/view?r=eyJrIjoiZWI0NmFiMjQtNzkyYi00YmExLWExNDAtNTllYmY4MDU2NjU0IiwidCI6IjJlNjdjZjFhLTJmYmItNDcxYS04ZjM3LWJlYjViNjg1ZTE5YSIsImMiOjEwfQ%3D%3D"
                frameborder="0"
                allowFullScreen="true"
            ></iframe>
        </div>
    );
}
