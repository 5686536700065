import React, { useRef, useState, useEffect, useCallback } from "react";
import { api } from "../../system/api";
import { Table, Modal, Drawer, Form, Space, Button, Input, InputNumber, Select, DatePicker, Tag } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import {
    SearchOutlined,
    UserOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import useUserInfo from "../../system/useUserInfo";
import dayjs from 'dayjs';
import { CSVLink } from "react-csv";
const { confirm } = Modal;
export default function HouseholdGroupMonthly() {

    const { userinfo } = useUserInfo();
    const [griddata, setGridData] = useState();
    const [exceldata, setexceldata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formtitle] = useState('Дундын хадгаламжийн бүлгийн сар бүрийн мэдээлэл');
    const [formdata] = Form.useForm();
    const [householdgroup, sethouseholdgroup] = useState([]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        await api
            .get(`/api/record/base/get_dropdown_item_list?type=householdgroup`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    sethouseholdgroup(res?.data?.retdata);
                }
            });

        await api
            .get(`/api/record/coach/get_householdgroupmonthly_list`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setGridData(res?.data?.retdata);
                    let list = [];
                    for (var i = 0; i < res?.data?.retdata.length; i++) {
                        let b = res?.data?.retdata[i];
                        let item = {};

                        item["Мэдээллийг хамарч буй сар"] = b.monthly2;
                        item["Дундын хадгаламжийн бүлгийн нэр"] = b.householdgroup;
                        item["Хайрцагт байгаа зээлийн сангийн нийт мөнгөн дүн"] = b.totalamount;
                        item["Нийт гаргасан зээлийн дүн"] = b.totalloan;
                        item["Нийт хадгаламжийн хэмжээ"] = b.totalsaving;
                        item["Нийт хувьцааны тоо"] = b.totalshares;
                        item["Төлөгдөөгүй зээлийн дүн"] = b.unloanamount;
                        item["Төлөгдөөгүй зээлийн тоо"] = b.unloancount;
                        item["Сүүлийн байдлаарх нийгмийн сангийн үлдэгдэл"] = b.balance;

                        list.push(item);
                    }
                    setexceldata(list);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const tableOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                getFormData(record.id);
            },
        };
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const gridcolumns = [
        {
            title: "Мэдээллийг хамарч буй сар",
            dataIndex: "monthly2",
            ...getColumnSearchProps("monthly2"),
            sorter: (a, b) => a.monthly2 - b.monthly2,
        },
        {
            title: "Дундын хадгаламжийн бүлгийн нэр",
            dataIndex: "householdgroup",
            ...getColumnSearchProps("householdgroup"),
            sorter: (a, b) => a.householdgroup - b.householdgroup,
        },
        {
            title: "Хайрцагт байгаа зээлийн сангийн нийт мөнгөн дүн",
            dataIndex: "totalamount",
            ...getColumnSearchProps("totalamount"),
            sorter: (a, b) => a.totalamount - b.totalamount,
        },
        {
            title: "Нийт гаргасан зээлийн дүн",
            dataIndex: "totalloan",
            ...getColumnSearchProps("totalloan"),
            sorter: (a, b) => a.totalloan - b.totalloan,
        },
        {
            title: "Нийт хадгаламжийн хэмжээ",
            dataIndex: "totalsaving",
            ...getColumnSearchProps("totalsaving"),
            sorter: (a, b) => a.totalsaving - b.totalsaving,
        },
        {
            title: "Нийт хувьцааны тоо",
            dataIndex: "totalshares",
            ...getColumnSearchProps("totalshares"),
            sorter: (a, b) => a.totalshares - b.totalshares,
        },
        {
            title: "Төлөгдөөгүй зээлийн дүн",
            dataIndex: "unloanamount",
            ...getColumnSearchProps("unloanamount"),
            sorter: (a, b) => a.unloanamount - b.unloanamount,
        },
        {
            title: "Төлөгдөөгүй зээлийн тоо",
            dataIndex: "unloancount",
            ...getColumnSearchProps("unloancount"),
            sorter: (a, b) => a.unloancount - b.unloancount,
        },
        {
            title: "Сүүлийн байдлаарх нийгмийн сангийн үлдэгдэл",
            dataIndex: "balance",
            ...getColumnSearchProps("balance"),
            sorter: (a, b) => a.balance - b.balance,
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDeleteConfirm = () => {
        confirm({
            title: "Устгах уу?",
            icon: <ExclamationCircleFilled />,
            //content: 'Some descriptions',
            okText: "Тийм",
            okType: "danger",
            cancelText: "Үгүй",
            onOk() {
                onDelete();
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    };

    const onDelete = async () => {
        await api
            .delete(
                `/api/record/coach/delete_householdgroupmonthly?id=${formdata.getFieldValue("id")}`
            )
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const onFinish = async (values) => {
        let fdata = formdata.getFieldsValue();
        fdata.monthly = fdata.monthly.format('YYYY.MM.DD');
        await api
            .post(`/api/record/coach/set_householdgroupmonthly`, fdata)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const getFormData = async (id) => {
        await api.get(`/api/record/coach/get_householdgroupmonthly?id=${id}`).then((res) => {
            if (res?.status === 200 && res?.data?.rettype === 0) {
                let fdata = res?.data?.retdata[0];
                fdata.monthly = dayjs(fdata.monthly, 'YYYY.MM.DD');
                formdata.setFieldsValue(fdata);
                showModal();
            }
        });
    };

    const newFormData = async () => {
        formdata.setFieldsValue(
            {
                id: 0,
                monthly: null,
                householdgroupid: null,
                totalamount: null,
                totalloan: null,
                totalsaving: null,
                totalshares: null,
                unloanamount: null,
                unloancount: null,
                balance: null,
            });
        showModal();
    };


    return (
        <div>
            <Button
                style={{ marginBottom: 16 }}
                icon={<PlusOutlined />}
                type="primary"
                hidden={userinfo.roleid === "4"}
                onClick={(e) => newFormData()}
            >
                {`${formtitle} нэмэх`}
            </Button>

            <Table
                size="small"
                title={() => (
                    <>
                        <Tag icon={<UserOutlined />} color="magenta">
                            {formtitle} <b>{exceldata.length}</b> харагдаж байна.
                        </Tag>

                        <CSVLink data={exceldata} filename={formtitle + ".csv"}>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                size="small"
                            >
                                Татах
                            </Button>
                        </CSVLink>
                    </>
                )}
                bordered
                loading={loading}
                columns={gridcolumns}
                dataSource={griddata}
                onRow={tableOnRow}
                pagination={true}
                rowKey={(record) => record.id}
            ></Table>

            <Drawer
                forceRender
                title={formtitle}
                width={720}
                onClose={handleCancel}
                open={isModalOpen}
                bodyStyle={{ paddingBottom: 80, }}
                extra={
                    <Space>
                        <Button
                            key="delete"
                            danger
                            onClick={showDeleteConfirm}
                            hidden={formdata.getFieldValue("id") === 0 || userinfo.roleid === "4"}
                        >
                            Устгах
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Болих
                        </Button>
                        <Button
                            key="save"
                            type="primary"
                            onClick={() => { formdata.submit() }}
                            hidden={userinfo.roleid === "4"}
                        >
                            Хадгалах
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={formdata}
                    onFinish={onFinish}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    labelAlign="left"
                    labelWrap
                >
                    <Form.Item name="id" label="Дугаар" hidden={true}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="monthly" label="Мэдээллийг хамарч буй сар" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <DatePicker style={{ width: "100%" }} placeholder="Өдөр сонгох" picker="month" />
                    </Form.Item>

                    <Form.Item name="householdgroupid" label="Дундын хадгаламжийн бүлгийн нэр" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <Select style={{ width: "100%" }}>
                            {householdgroup?.map((t, i) => (
                                <Select.Option key={i} value={t.id}>
                                    {t.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="totalamount" label="Хайрцагт байгаа зээлийн сангийн нийт мөнгөн дүн" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber
                            style={{ width: "100%" }}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>

                    <Form.Item name="totalloan" label="Нийт гаргасан зээлийн дүн" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber
                            style={{ width: "100%" }}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>

                    <Form.Item name="totalsaving" label="Нийт хадгаламжийн хэмжээ" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber
                            style={{ width: "100%" }}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>

                    <Form.Item name="totalshares" label="Нийт хувьцааны тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber
                            style={{ width: "100%" }}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>

                    <Form.Item name="unloanamount" label="Төлөгдөөгүй зээлийн дүн" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber
                            style={{ width: "100%" }}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>

                    <Form.Item name="unloancount" label="Төлөгдөөгүй зээлийн тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber
                            style={{ width: "100%" }}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>

                    <Form.Item name="balance" label="Сүүлийн байдлаарх нийгмийн сангийн үлдэгдэл" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber
                            style={{ width: "100%" }}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>

                </Form>
            </Drawer>
        </div>
    );
}

