import React, { useRef, useState, useEffect, useCallback } from "react";
import { api } from "../../system/api";
import { Table, Modal, Drawer, Form, Space, Button, Input, InputNumber, Select, DatePicker, Tag, } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import {
    SearchOutlined,
    UserOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import useUserInfo from "../../system/useUserInfo";
import dayjs from 'dayjs';
import { CSVLink } from "react-csv";
const { confirm } = Modal;
export default function HouseholdGroup() {

    const { userinfo } = useUserInfo();
    const [griddata, setGridData] = useState();
    const [exceldata, setexceldata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [coachlist, setcoachlist] = useState([]);
    const [district, setdistrict] = useState([]);
    const [formtitle] = useState('Дундын хадгаламжийн бүлэг');
    const [formdata] = Form.useForm();
    const sections = [];
    for (let i = 1; i < 50; i++) {
        sections.push({
            label: i.toString(),
            value: i.toString(),
        });
    }

    const [statuslist] = useState([
        {
            value: 1,
            text: "Сургагч багш/коучаар чиглүүлэгтэй бүлэг",
            label: "Сургагч багш/коучаар чиглүүлэгтэй бүлэг",
        },
        {
            value: 2,
            text: "Өөрийгөө удирддаг бүлэг",
            label: "Өөрийгөө удирддаг бүлэг",
        },
    ]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        await api.get(`/api/record/base/get_district_list`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setdistrict(res?.data?.retdata);
                }
            });
        await api
            .get(`/api/record/base/get_householdgroup_list`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setGridData(res?.data?.retdata);
                    let list = [];
                    for (var i = 0; i < res?.data?.retdata.length; i++) {
                        let b = res?.data?.retdata[i];
                        let item = {};

                        item["Дундын хадгаламжийн бүлгийн нэр"] = b.name;
                        item["Байгуулагдсан огноо"] = b.groupdate;
                        item["Дүүрэг"] = b.districtname;
                        item["Хороо"] = b.section;
                        item["Бүлгийг зохион байгуулах хүний нэр"] = b.coachname;
                        item["Бүгд"] = b.firstmembercount;
                        item["Эмэгтэй"] = b.femalemembercount;
                        item["Хөгжлийн бэрхшээлтэй"] = b.disabledmembercount;
                        item["12-18 насны"] = b.childmembercount;
                        item["Бүлэг анх байгуулагдах үеийн нэгж хувьцааны үнэ"] = b.unitprice;
                        item["Анхны хурлын нийт хувьцааны тоо"] = b.firststock;
                        item["Бүлгийн статус"] = b.groupstatus;


                        list.push(item);
                    }
                    setexceldata(list);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const tableOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                getFormData(record.id);
            },
        };
    };

    useEffect(() => {
        api.get(`/api/record/coach/get_coach_list`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setcoachlist(res?.data?.retdata);
                }
            });
        fetchData();
    }, [fetchData]);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const gridcolumns = [
        {
            title: "Дундын хадгаламжийн бүлгийн нэр",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: "Байгуулагдсан огноо",
            dataIndex: "groupdate",
            ...getColumnSearchProps("groupdate"),
            sorter: (a, b) => a.groupdate - b.groupdate,
        },
        {
            title: "Дүүрэг",
            dataIndex: "districtname",
            ...getColumnSearchProps("districtname"),
            sorter: (a, b) => a.districtname - b.districtname,
        },
        {
            title: "Хороо",
            dataIndex: "section",
            ...getColumnSearchProps("section"),
            sorter: (a, b) => a.section - b.section,
        },
        {
            title: "Бүлгийг зохион байгуулах хүний нэр",
            dataIndex: "coachname",
            ...getColumnSearchProps("coachname"),
            sorter: (a, b) => a.coachname - b.coachname,
        },
        {
            title: "Бүлэг анх байгуулагдах үеийн гишүүдийн тоо",
            children: [
                {
                    title: "Бүгд",
                    dataIndex: "firstmembercount",
                    ...getColumnSearchProps("firstmembercount"),
                    sorter: (a, b) => a.firstmembercount - b.firstmembercount,
                },
                {
                    title: "Эмэгтэй",
                    dataIndex: "femalemembercount",
                    ...getColumnSearchProps("femalemembercount"),
                    sorter: (a, b) => a.femalemembercount - b.femalemembercount,
                },
                {
                    title: "Хөгжлийн бэрхшээлтэй",
                    dataIndex: "disabledmembercount",
                    ...getColumnSearchProps("disabledmembercount"),
                    sorter: (a, b) => a.disabledmembercount - b.disabledmembercount,
                },
                {
                    title: "12-18 насны",
                    dataIndex: "childmembercount",
                    ...getColumnSearchProps("childmembercount"),
                    sorter: (a, b) => a.childmembercount - b.childmembercount,
                },
            ]
        },
        {
            title: "Бүлэг анх байгуулагдах үеийн нэгж хувьцааны үнэ",
            dataIndex: "unitprice",
            ...getColumnSearchProps("unitprice"),
            sorter: (a, b) => a.unitprice - b.unitprice,
        },
        {
            title: "Анхны хурлын нийт хувьцааны тоо",
            dataIndex: "firststock",
            ...getColumnSearchProps("firststock"),
            sorter: (a, b) => a.firststock - b.firststock,
        },
        {
            title: "Бүлгийн статус",
            dataIndex: "groupstatus",
            render: (text, record, index) => {
                return (
                    <Select
                        value={record?.groupstatus}
                        disabled
                        bordered={false}
                        options={statuslist}
                    />
                );
            },
            filters: statuslist,
            onFilter: (value, record) => record.groupstatus === value,
            sorter: (a, b) => a.groupstatus - b.groupstatus,
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDeleteConfirm = () => {
        confirm({
            title: "Устгах уу?",
            icon: <ExclamationCircleFilled />,
            //content: 'Some descriptions',
            okText: "Тийм",
            okType: "danger",
            cancelText: "Үгүй",
            onOk() {
                onDelete();
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    };

    const onDelete = async () => {
        await api
            .delete(
                `/api/record/base/delete_householdgroup?id=${formdata.getFieldValue("id")}`
            )
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const onFinish = async (values) => {
        let fdata = formdata.getFieldsValue();
        if (fdata.groupdate)
            fdata.groupdate = fdata.groupdate.format('YYYY.MM.DD');
        await api
            .post(`/api/record/base/set_householdgroup`, fdata)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const getFormData = async (id) => {
        await api.get(`/api/record/base/get_householdgroup?id=${id}`).then((res) => {
            if (res?.status === 200 && res?.data?.rettype === 0) {
                let fdata = res?.data?.retdata[0];
                if (fdata.groupdate)
                    fdata.groupdate = dayjs(fdata.groupdate, 'YYYY.MM.DD');
                formdata.setFieldsValue(fdata);
                showModal();
            }
        });
    };

    const newFormData = async () => {
        formdata.setFieldsValue(
            {
                id: 0,
                name: null,
                coachid: [],
                unitprice: null,
                districtid: null,
                section: [],
                groupdate: null,
                firstmembercount: null,
                femalemembercount: null,
                disabledmembercount: null,
                childmembercount: null,
                firststock: null,
                groupstatus: null,
            });
        showModal();
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current >= dayjs().endOf('day');
    };

    return (
        <div>
            <Button
                style={{ marginBottom: 16 }}
                icon={<PlusOutlined />}
                type="primary"
                hidden={userinfo.roleid === "4"}
                onClick={(e) => newFormData()}
            >
                Бүлэг нэмэх
            </Button>

            <Table
                size="small"
                title={() => (
                    <>
                        <Tag icon={<UserOutlined />} color="magenta">
                            {formtitle} <b>{exceldata.length}</b> харагдаж байна.
                        </Tag>

                        <CSVLink data={exceldata} filename={"Дундын хадгаламжийн бүлэг.csv"}>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                size="small"
                            >
                                Татах
                            </Button>
                        </CSVLink>
                    </>
                )}
                bordered
                loading={loading}
                columns={gridcolumns}
                dataSource={griddata}
                onRow={tableOnRow}
                pagination={true}

                rowKey={(record) => record.id}
            ></Table>

            <Drawer
                forceRender
                title={formtitle}
                width={720}
                onClose={handleCancel}
                open={isModalOpen}
                bodyStyle={{ paddingBottom: 80, }}
                extra={
                    <Space>
                        <Button
                            key="delete"
                            danger
                            onClick={showDeleteConfirm}
                            hidden={formdata.getFieldValue("id") === 0 || userinfo.roleid === "4"}
                        >
                            Устгах
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Болих
                        </Button>
                        <Button
                            key="save"
                            type="primary"
                            onClick={() => { formdata.submit() }}
                            hidden={userinfo.roleid === "4"}
                        >
                            Хадгалах
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={formdata}
                    onFinish={onFinish}
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 12 }}
                    labelAlign="left"
                    labelWrap>

                    <Form.Item name="id" label="Дугаар" hidden={true}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="name" label="Дундын хадгаламжийн бүлгийн нэр" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="groupdate" label="Байгуулагдсан огноо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <DatePicker
                            disabledDate={disabledDate}
                            style={{ width: "100%" }}
                            placeholder="Өдөр сонгох" />
                    </Form.Item>

                    <Form.Item name="districtid" label="Дүүрэг" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: "100%" }}>
                            {district?.map((t, i) => (<Select.Option key={i} value={t.districtid}>{t.name}</Select.Option>))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="section" label="Хороо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            options={sections}
                        >
                        </Select>
                    </Form.Item>

                    <Form.Item name="coachid" label="Бүлгийг зохион байгуулах хүний нэр" rules={[{ required: false, message: "Утга оруулна уу!" }]} >
                        <Select mode="multiple" style={{ width: "100%" }}>
                            {coachlist?.map((t, i) => (
                                <Select.Option key={i} value={t.coachid.toString()}>
                                    {t.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="firstmembercount" label="Бүлэг анх байгуулагдах үеийн гишүүдийн тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <InputNumber
                            min={0}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item name="femalemembercount" label="Бүлэг анх байгуулагдах үеийн эмэгтэй гишүүдийн тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber
                            min={0}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item name="disabledmembercount" label="Бүлэг анх байгуулагдах үеийн хөгжлийн бэрхшээлтэй гишүүдийн тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <InputNumber
                            min={0}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item name="childmembercount" label="Бүлэг анх байгуулагдах үеийн 12-18 насны бүлгийн гишүүдийн тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <InputNumber
                            min={0}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item name="unitprice" label="Бүлэг анх байгуулагдах үеийн нэгж хувьцааны үнэ" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <InputNumber
                            min={0}
                            style={{ width: "100%" }}
                            formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                    </Form.Item>

                    <Form.Item name="firststock" label="Анхны хурлын нийт хувьцааны тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <InputNumber
                            min={0}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>

                    <Form.Item name="groupstatus" label="Бүлгийн статус" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select
                            style={{ width: "100%" }}
                            options={statuslist}
                        />
                    </Form.Item>

                </Form>
            </Drawer>
        </div>
    );
}
