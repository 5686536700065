import React, { useRef, useState, useEffect, useCallback } from "react";
import { api } from "../../system/api";
import useUserInfo from "../../system/useUserInfo";
import { Table, Modal, Drawer, Space, Form, Button, Typography, Switch, DatePicker, Input, Tag, InputNumber } from "antd";
import {
    ExclamationCircleFilled,
    SearchOutlined,
    DownloadOutlined,
    UserOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
const { confirm } = Modal;
const { Text } = Typography;

export default function Meeting() {
    const { userinfo } = useUserInfo();
    const [griddata, setGridData] = useState();
    const [exceldata, setexceldata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formdata] = Form.useForm();

    const fetchData = useCallback(() => {
        setLoading(true);
        let coachid = (isNaN(userinfo.coachid) ? 0 : userinfo.coachid) * 1;
        let districtid = (isNaN(userinfo.districtid) ? 0 : userinfo.districtid) * 1;
        api
            .get(`/api/record/coach/get_meetingattendance_list?coachid=${coachid}&districtid=${districtid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let tdata = res?.data?.retdata.filter(row => row.isactive === true);
                    setGridData(tdata);
                    let list = [];
                    for (var i = 0; i < tdata.length; i++) {
                        let b = tdata[i];
                        let item = {};
                        item["Коуч"] = b.coachid;
                        item["Дундын хадгаламжийн бүлгийн хурлын огноо"] = b.meetingdate;
                        item["Дундын хадгаламжийн бүлгийн нэр"] = b.householdgroupname;
                        item["Хуралд оролцсон өрхийн дугаар"] = b.householdid;
                        item["Гол оролцогч гишүүний нэр"] = b.name;
                        item["Гол оролцогч гишүүний хүйс"] = b.gender;
                        item["Гол оролцогч гишүүн хөгжлийн бэрхшээлтэй эсэх"] = b.isdisabled;
                        item["Гол оролцогч гишүүн 12-18 насны эсэх"] = b.ischild;
                        item["Тухайн хурлаар худалдан авсан хувьцааны тоо"] = b.quantity;
                        item["Хуримтлуулсан мөнгөн дүн"] = b.famount;
                        list.push(item);
                    }
                    setexceldata(list);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [userinfo]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const gridcolumns = [
        {
            title: "Коуч",
            dataIndex: "coachid",
            ...getColumnSearchProps("coachid"),
            sorter: (a, b) => a.coachid - b.coachid,
        },
        {
            title: "Дундын хадгаламжийн бүлгийн хурлын огноо",
            dataIndex: "meetingdate",
            ...getColumnSearchProps("meetingdate"),
            sorter: (a, b) => a.meetingdate - b.meetingdate,
        },
        {
            title: "Дундын хадгаламжийн бүлгийн нэр",
            dataIndex: "householdgroupname",
            ...getColumnSearchProps("householdgroupname"),
            sorter: (a, b) => a.householdgroupname - b.householdgroupname,
        },
        {
            title: "Хуралд оролцсон өрхийн дугаар",
            dataIndex: "householdid",
            ...getColumnSearchProps("householdid"),
            sorter: (a, b) => a.householdid - b.householdid,
        },
        {
            title: "Гол оролцогч гишүүний нэр",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: "Гол оролцогч гишүүний хүйс",
            dataIndex: "gender",
            ...getColumnSearchProps("gender"),
            sorter: (a, b) => a.gender - b.gender,
        },
        {
            title: "Гол оролцогч гишүүн хөгжлийн бэрхшээлтэй эсэх",
            dataIndex: "isdisabled",
            ...getColumnSearchProps("isdisabled"),
            sorter: (a, b) => a.isdisabled - b.isdisabled,
        },
        {
            title: "Гол оролцогч гишүүн 12-18 насны эсэх",
            dataIndex: "ischild",
            ...getColumnSearchProps("ischild"),
            sorter: (a, b) => a.ischild - b.ischild,
        },
        {
            title: "Тухайн хурлаар худалдан авсан хувьцааны тоо",
            dataIndex: "quantity",
            ...getColumnSearchProps("quantity"),
            sorter: (a, b) => a.quantity - b.quantity,
        },
        {
            title: "Хуримтлуулсан мөнгөн дүн",
            dataIndex: "famount",
            align: "right",
            ...getColumnSearchProps("famount"),
            sorter: (a, b) => a.famount - b.famount,
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDeleteConfirm = () => {
        confirm({
            title: "Устгах уу?",
            icon: <ExclamationCircleFilled />,
            //content: 'Some descriptions',
            okText: "Тийм",
            okType: "danger",
            cancelText: "Үгүй",
            onOk() {
                onDelete();
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    };

    const onDelete = async () => {
        await api
            .delete(
                `/api/record/coach/delete_meetingattendance?id=${formdata.getFieldValue(
                    "entryid"
                )}`
            )
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const onFinish = async (values) => {
        let fdata = formdata.getFieldsValue();
        fdata.meetingdate = fdata.meetingdate.format('YYYY.MM.DD HH:mm:ss');
        await api
            .post(`/api/record/coach/set_meetingattendance`, fdata)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const getFormData = async (entryid) => {
        await api
            .get(`/api/record/coach/get_meetingattendance?id=${entryid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let fdata = res?.data?.retdata[0];
                    fdata.meetingdate = dayjs(fdata.meetingdate, 'YYYY.MM.DD HH:mm:ss');
                    formdata.setFieldsValue(fdata);
                    showModal();
                }
            });
    };

    const tableOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                getFormData(record.entryid);
            },
        };
    };

    return (
        <div>

            <Table
                size="small"
                bordered
                title={() => (
                    <>
                        <Tag icon={<UserOutlined />} color="magenta">
                            Хурлын ирцийн мэдээлэл <b>{exceldata.length}</b> харагдаж байна.
                        </Tag>

                        <CSVLink data={exceldata} filename={"Хурлын ирцийн жагсаалт.csv"}>
                            <Button type="primary" icon={<DownloadOutlined />} size="small">
                                Татах
                            </Button>
                        </CSVLink>
                    </>
                )}
                onChange={(pagination, filters, sorter, extra) =>
                    setexceldata(extra.currentDataSource)
                }
                loading={loading}
                columns={gridcolumns}
                dataSource={griddata}
                onRow={tableOnRow}
                pagination={true}
                rowKey={(record) => record.entryid}
                summary={(pageData) => {
                    let totalamount = 0;
                    let totalquantity = 0;
                    pageData.forEach(({ amount, quantity }) => {
                        totalamount += parseFloat(amount);
                        totalquantity += quantity;
                    });
                    totalamount = totalamount
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                    return (
                        <>
                            <Table.Summary.Row style={{ background: "#fafafa" }}>
                                <Table.Summary.Cell index={0}>Нийт:</Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} />
                                <Table.Summary.Cell index={3} />
                                <Table.Summary.Cell index={4} />
                                <Table.Summary.Cell index={5} />
                                <Table.Summary.Cell index={6} />
                                <Table.Summary.Cell index={7} />
                                <Table.Summary.Cell index={8}>
                                    <Text>{totalquantity}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={9} align="right">
                                    <Text>{totalamount}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            ></Table>

            <Drawer
                forceRender
                title="Хурлын ирцийн мэдээлэл нэмэх"
                open={isModalOpen}
                width={720}
                onClose={handleCancel}
                bodyStyle={{ paddingBottom: 80, }}
                extra={
                    <Space>
                        <Button
                            key="delete"
                            danger
                            onClick={showDeleteConfirm}
                            hidden={formdata.getFieldValue("entryid") === 0 || userinfo.roleid === "4"}
                        >
                            Устгах
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Болих
                        </Button>
                        <Button
                            key="save"
                            type="primary"
                            onClick={() => { formdata.submit() }}
                            hidden={userinfo.roleid === "4"}
                        >
                            Хадгалах
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={formdata}
                    onFinish={onFinish}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    labelAlign="left"
                    labelWrap
                    onFieldsChange={(changedFields, allFields) => {
                        if (changedFields.length === 1) {
                            if (changedFields[0]?.name[0] === 'quantity' || changedFields[0]?.name[0] === 'unitprice') {
                                formdata.setFieldValue('amount', formdata.getFieldValue('quantity') * formdata.getFieldValue('unitprice'));
                            }
                        }
                    }}
                >

                    <Form.Item name="entryid" hidden={true} />
                    <Form.Item name="coachid" hidden={true} />
                    <Form.Item name="householdid" hidden={true} />
                    <Form.Item
                        name="meetingdate"
                        label="Бүлгийн хурлын огноо" rules={[{ required: true, message: "Утга оруулна уу!" }]}
                    >
                        <DatePicker style={{ width: "100%" }} placeholder="Өдөр сонгох" />
                    </Form.Item>

                    <Form.Item
                        name="isjoin"
                        label="Бүлгийн хуралд оролцсон эсэх"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="Тийм"
                            unCheckedChildren="Үгүй"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item name="quantity" label="Худалдан авсан хувьцааны тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <InputNumber
                            min={0}
                            style={{ width: "100%" }}
                            placeholder="Хувьцааны тоо"
                        />
                    </Form.Item>
                    <Form.Item name="unitprice" label="Нэгж хувьцааны үнэ" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <InputNumber
                            style={{ width: "100%" }}
                            placeholder="Мөнгөн дүн"
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                    <Form.Item name="amount" label="Хуримтлуулсан мөнгөн дүн" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <InputNumber
                            style={{ width: "100%" }}
                            placeholder="Мөнгөн дүн"
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}
