import React, { useRef, useState, useEffect, useCallback } from "react";
import { api } from "../../system/api";
import useUserInfo from "../../system/useUserInfo";
import { Table, Input, Space, Tag, Button, } from "antd";
import { Modal, Drawer, Form, DatePicker, Select, Divider, InputNumber, Switch, Typography } from "antd";
import {
    ExclamationCircleFilled,
    SearchOutlined,
    DownloadOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";
import dayjs from 'dayjs';
const { confirm } = Modal;
const { Text } = Typography;

export default function Training() {
    const { userinfo } = useUserInfo();
    const [griddata, setGridData] = useState();
    const [exceldata, setexceldata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formdata] = Form.useForm();
    const [relationship, setrelationship] = useState([]);
    const [trainingcategory, settrainingcategory] = useState([]);
    const [trainingtypecopy, settrainingtypecopy] = useState([]);
    const [trainingtype, settrainingtype] = useState([]);
    const [trainingandactivitycopy, settrainingandactivitycopy] = useState([]);
    const [trainingandactivity, settrainingandactivity] = useState([]);
    const [organization, setorganization] = useState([]);
    const [formoftraining, setformoftraining] = useState([]);

    const fetchData = useCallback(() => {
        setLoading(true);
        let coachid = (isNaN(userinfo.coachid) ? 0 : userinfo.coachid) * 1;
        let districtid = (isNaN(userinfo.districtid) ? 0 : userinfo.districtid) * 1;
        api.get(`/api/record/coach/get_training_list?coachid=${coachid}&districtid=${districtid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let tdata = res?.data?.retdata.filter(row => row.isactive === true);
                    setGridData(tdata);
                    let list = [];
                    for (var i = 0; i < tdata.length; i++) {
                        let b = tdata[i];
                        let item = {};
                        item["Коуч"] = b.coachid;
                        item["Огноо"] = b.trainingdate;
                        item["Өрхийн дугаар"] = b.householdid;
                        item["Дүүрэг"] = b.districtname;
                        item["Хороо"] = b.section;
                        item["Сургалтын үндсэн чиглэл"] = b.trainingcategoryname;
                        item["Сургалтын төрөл"] = b.trainingtype;
                        item["Сургалтын нэр"] = b.trainingandactivity;
                        item["Сургалтын явагдсан хэлбэр"] = b.formoftrainingname;
                        item["Сургалт өгсөн байгууллага / ажилтан"] = b.organization;
                        item["Оролцогчийн нэр"] = b.membername;
                        item["Оролцогчийн хүйс"] = b.gender;
                        item["Оролцогч нь өрхийн гол гишүүн мөн эсэх"] = b.isparticipant;

                        list.push(item);
                    }
                    setexceldata(list);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [userinfo]);

    useEffect(() => {

        api.get(`/api/record/base/get_dropdown_item_list?type=trainingcategory`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    settrainingcategory(res?.data?.retdata);
                }
            });
        api.get(`/api/record/base/get_dropdown_item_list?type=trainingtype`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    settrainingtype(res?.data?.retdata);
                }
            });
        api.get(`/api/record/base/get_dropdown_item_list?type=trainingandactivity`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    settrainingandactivity(res?.data?.retdata);
                }
            });
        api.get(`/api/record/base/get_dropdown_item_list?type=organization`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setorganization(res?.data?.retdata);
                }
            });
        api.get(`/api/record/base/get_dropdown_item_list?type=formoftraining`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setformoftraining(res?.data?.retdata);
                }
            });
        fetchData();
    }, [fetchData]);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });


    const gridcolumns = [
        {
            title: "Коуч",
            dataIndex: "coachid",
            ...getColumnSearchProps("coachid"),
            sorter: (a, b) => a.coachid - b.coachid,
        },
        {
            title: "Огноо",
            dataIndex: "trainingdate",
            width: 120,
            ...getColumnSearchProps("trainingdate"),
            sorter: (a, b) => a.trainingdate - b.trainingdate,
        },
        {
            title: "Өрхийн дугаар",
            dataIndex: "householdid",
            ...getColumnSearchProps("householdid"),
            sorter: (a, b) => a.householdid - b.householdid,
        },
        {
            title: "Дүүрэг",
            dataIndex: "districtname",
            ...getColumnSearchProps("districtname"),
            sorter: (a, b) => a.districtname - b.districtname,
        },
        {
            title: "Хороо",
            dataIndex: "section",
            ...getColumnSearchProps("section"),
            sorter: (a, b) => a.section - b.section,
        },
        {
            title: "Сургалтын үндсэн чиглэл",
            dataIndex: "trainingcategoryname",
            ...getColumnSearchProps("trainingcategoryname"),
            sorter: (a, b) => a.trainingcategoryname - b.trainingcategoryname,
        },
        {
            title: "Сургалтын төрөл",
            dataIndex: "trainingtype",
            ...getColumnSearchProps("trainingtype"),
            sorter: (a, b) => a.trainingtype - b.trainingtype,
        },
        {
            title: "Сургалтын нэр",
            dataIndex: "trainingandactivity",
            ...getColumnSearchProps("trainingandactivity"),
            sorter: (a, b) => a.trainingandactivity - b.trainingandactivity,
        },
        {
            title: "Сургалтын явагдсан хэлбэр",
            dataIndex: "formoftrainingname",
            ...getColumnSearchProps("formoftrainingname"),
            sorter: (a, b) => a.formoftrainingname - b.formoftrainingname,
        },
        {
            title: "Сургалт өгсөн байгууллага / ажилтан",
            dataIndex: "organization",
            ...getColumnSearchProps("organization"),
            sorter: (a, b) => a.organization - b.organization,
        },
        {
            title: "Оролцогчийн нэр",
            dataIndex: "membername",
            ...getColumnSearchProps("membername"),
            sorter: (a, b) => a.membername - b.membername,
        },
        {
            title: "Оролцогчийн хүйс",
            dataIndex: "gender",
            ...getColumnSearchProps("gender"),
            sorter: (a, b) => a.gender - b.gender,
        },
        {
            title: "Оролцогч нь өрхийн гол гишүүн мөн эсэх",
            dataIndex: "isparticipant",
            ...getColumnSearchProps("isparticipant"),
            sorter: (a, b) => a.isparticipant - b.isparticipant,
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDeleteConfirm = () => {
        confirm({
            title: "Устгах уу?",
            icon: <ExclamationCircleFilled />,
            okText: "Тийм",
            okType: "danger",
            cancelText: "Үгүй",
            onOk() {
                onDelete();
            },
        });
    };

    const onDelete = async () => {
        await api
            .delete(
                `/api/record/coach/delete_training?id=${formdata.getFieldValue(
                    "entryid"
                )}`
            )
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const onFinish = async (values) => {
        let fdata = formdata.getFieldsValue();
        fdata.trainingdate = fdata.trainingdate.format('YYYY.MM.DD HH:mm:ss');
        await api
            .post(`/api/record/coach/set_training`, fdata)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const getFormData = async (entryid) => {
        await api
            .get(`/api/record/coach/get_training?id=${entryid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let fdata = res?.data?.retdata[0];
                    fdata.trainingdate = dayjs(fdata.trainingdate, 'YYYY.MM.DD HH:mm:ss');
                    formdata.setFieldsValue(fdata);
                    showModal();
                }
            });
    };

    const tableOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {

                api.get(`/api/record/households/get_householdmember_list?householdid=${record.householdid}`)
                    .then((res) => {
                        if (res?.status === 200 && res?.data?.rettype === 0) {
                            setrelationship(res?.data?.retdata);
                        }
                    });

                settrainingtypecopy(trainingtype?.filter(row => row?.trainingcategoryid === record.trainingcategoryid));
                settrainingandactivitycopy(trainingandactivity?.filter(row => row?.trainingtypeid === record.trainingtypeid));
                getFormData(record.entryid);
            },
        };
    };

    return (
        <div>

            <Table
                size="small"
                bordered
                title={() => (
                    <>
                        <Tag icon={<UserOutlined />} color="magenta">
                            Сургалтны мэдээлэл <b>{exceldata.length}</b> харагдаж байна.
                        </Tag>

                        <CSVLink data={exceldata} filename={"Сургалтны жагсаалт.csv"}>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                size="small"
                            >
                                Татах
                            </Button>
                        </CSVLink>
                    </>
                )}
                onChange={(pagination, filters, sorter, extra) =>
                    setexceldata(extra.currentDataSource)
                }
                loading={loading}
                columns={gridcolumns}
                dataSource={griddata}
                onRow={tableOnRow}
                pagination={true}
                rowKey={(record) => record.entryid}
            ></Table>

            <Drawer
                forceRender
                title="Сургалтны мэдээлэл нэмэх"
                open={isModalOpen}
                width={720}
                onClose={handleCancel}
                centered
                bodyStyle={{ paddingBottom: 80, }}
                extra={
                    <Space>
                        <Button
                            key="delete"
                            danger
                            onClick={showDeleteConfirm}
                            hidden={formdata.getFieldValue("entryid") === 0 || userinfo.roleid === "4"}
                        >
                            Устгах
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Болих
                        </Button>
                        <Button
                            key="save"
                            type="primary"
                            onClick={() => { formdata.submit() }}
                            hidden={userinfo.roleid === "4"}
                        >
                            Хадгалах
                        </Button>
                    </Space>
                }
            >
                <Divider />
                <Form
                    form={formdata}
                    onFinish={onFinish}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    labelAlign="left"
                    labelWrap
                    onFieldsChange={(changedFields, allFields) => {
                        if (changedFields.length === 1) {
                            if (changedFields[0]?.name[0] === 'trainingcategoryid') {
                                formdata.setFieldValue('trainingtypeid', null);
                                formdata.setFieldValue('trainingandactivityid', null);
                                settrainingtypecopy(trainingtype?.filter(row => row?.trainingcategoryid === formdata?.getFieldValue("trainingcategoryid")));
                            }
                            else if (changedFields[0]?.name[0] === 'trainingtypeid') {
                                formdata.setFieldValue('trainingandactivityid', null);
                                settrainingandactivitycopy(trainingandactivity?.filter(row => row?.trainingtypeid === formdata?.getFieldValue("trainingtypeid")));
                            }
                        }

                    }}
                >
                    <Form.Item name="entryid" hidden={true} />
                    <Form.Item name="householdid" hidden={true} />
                    <Form.Item name="trainingdate" label="Огноо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <DatePicker style={{ width: "100%" }} placeholder="Өдөр сонгох" />
                    </Form.Item>
                    <Form.Item name="trainingcategoryid" label="Сургалтын үндсэн чиглэл" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: '100%' }}>
                            {trainingcategory?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="trainingtypeid" label="Сургалтын төрөл" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: '100%' }}>
                            {trainingtypecopy.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="trainingandactivityid" label="Сургалтын нэр" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: '100%' }}>
                            {trainingandactivitycopy?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="formoftrainingid" label="Сургалтын явагдсан хэлбэр" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: '100%' }}>
                            {formoftraining?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="organizationid" label="Сургалт өгсөн байгууллага / ажилтан" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: '100%' }}>
                            {organization?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="duration" label="Сургалтын үргэжилсэн хугацаа" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <InputNumber placeholder="Хугацаа" min={0} style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                        name="isjoin"
                        label="Өрхөөс уг сургалтад хамрагдсан эсэх"
                        valuePropName="checked"
                        hidden={true}
                    >
                        <Switch
                            checkedChildren="Тийм"
                            unCheckedChildren="Үгүй"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item name="memberid" label="Сургалтанд хамрагдсан өрхийн гишүүний нэр" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: "100%" }}>
                            {relationship?.map((t, i) => (
                                <Select.Option key={i} value={t.memberid}>
                                    {t.isparticipant === 'Тийм' ? <Text type="success">{t.name}</Text> : <Text>{t.name}</Text>}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                </Form>
            </Drawer>
        </div>
    );
}
