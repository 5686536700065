import React from "react";


export default function ProgressReport() {

    return (
        <>
            <iframe
                title="Report Section"
                width="100%"
                height="800"
                src="https://app.powerbi.com/view?r=eyJrIjoiMTMyN2ExNTUtMzY5MS00NTAwLWE2ZDYtY2I1YmY0OTMxYzQ1IiwidCI6IjJlNjdjZjFhLTJmYmItNDcxYS04ZjM3LWJlYjViNjg1ZTE5YSIsImMiOjEwfQ%3D%3D"
                frameborder="0"
                allowFullScreen="true">
            </iframe>
        </>
    );
}
