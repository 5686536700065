import React, { useRef, useState, useEffect, useCallback } from "react";
import { api } from "../../system/api";
import { Table, Modal, Drawer, Form, Space, Button, Input, InputNumber, DatePicker, Select, Tag } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import {
    SearchOutlined,
    UserOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import useUserInfo from "../../system/useUserInfo";
import dayjs from 'dayjs';
import { CSVLink } from "react-csv";
const { confirm } = Modal;
export default function HouseholdGroupCycle() {

    const { userinfo } = useUserInfo();
    const [griddata, setGridData] = useState();
    const [exceldata, setexceldata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formtitle] = useState('Дундын хадгаламжийн бүлгийн цикл бүрийн мэдээлэл');
    const [formdata] = Form.useForm();
    const [householdgroup, sethouseholdgroup] = useState([]);
    const [statuslist] = useState([
        {
            value: 1,
            label: "Сургагч багш/коучаар чиглүүлэгтэй бүлэг",
        },
        {
            value: 2,
            label: "Өөрийгөө удирддаг бүлэг",
        },
    ]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        await api
            .get(`/api/record/base/get_dropdown_item_list?type=householdgroup`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    sethouseholdgroup(res?.data?.retdata);
                }
            });

        await api
            .get(`/api/record/coach/get_householdgroupcycle_list`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setGridData(res?.data?.retdata);
                    let list = [];
                    for (var i = 0; i < res?.data?.retdata.length; i++) {
                        let b = res?.data?.retdata[i];
                        let item = {};
                        item["Дундын хадгаламжийн бүлгийн нэр"] = b.householdgroup;
                        item["Бүлгийн циклийн дугаар"] = b.cycleno;
                        item["Тухайн циклийн  хадгаламж хийсэн анхны өдөр"] = b.cycledate;
                        item["Бүлгийн статус"] = b.cyclestatus;
                        item["Бүлгийг хянагч чиглүүлэгч коучийн нэр"] = b.coachname;
                        item["Бүртгэлтэй гишүүдийн тоо"] = b.membercount;
                        item["Бүртгэлтэй эмэгтэй гишүүдийн тоо"] = b.femalecount;
                        item["Бүртгэлтэй хөгжлийн бэрхшээлтэй  гишүүдийн тоо"] = b.disabledcount;
                        item["Бүртгэлтэй 12 - 18 насны бүлгийн гишүүдийн тоо"] = b.childcount;
                        item["Тухайн бүлгээс гарсан гишүүдийн тоо"] = b.leftcount;
                        item["Цикл эхлэх үеийн хөрөнгө"] = b.cycleassets;

                        list.push(item);
                    }
                    setexceldata(list);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const tableOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                getFormData(record.id);
            },
        };
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const gridcolumns = [
        {
            title: "Дундын хадгаламжийн бүлгийн нэр",
            dataIndex: "householdgroup",
            ...getColumnSearchProps("householdgroup"),
            sorter: (a, b) => a.householdgroup - b.householdgroup,
        },
        {
            title: "Бүлгийн циклийн дугаар",
            dataIndex: "cycleno",
            ...getColumnSearchProps("cycleno"),
            sorter: (a, b) => a.cycleno - b.cycleno,
        },
        {
            title: "Тухайн циклийн  хадгаламж хийсэн анхны өдөр",
            dataIndex: "cycledate",
            ...getColumnSearchProps("cycledate"),
            sorter: (a, b) => a.cycledate - b.cycledate,
        },
        {
            title: "Бүлгийн статус",
            dataIndex: "cyclestatus",
            render: (text, record, index) => {
                return (
                    <Select
                        value={record?.cyclestatus}
                        disabled
                        bordered={false}
                        options={statuslist}
                    />
                );
            },
            filters: [statuslist],
            onFilter: (value, record) => record.cyclestatus === value,
            sorter: (a, b) => a.cyclestatus - b.cyclestatus,
        },
        {
            title: "Бүлгийг хянагч чиглүүлэгч коучийн нэр",
            dataIndex: "coachname",
            ...getColumnSearchProps("coachname"),
            sorter: (a, b) => a.coachname - b.coachname,
        },
        {
            title: "Бүртгэлтэй гишүүдийн тоо",
            dataIndex: "membercount",
            ...getColumnSearchProps("membercount"),
            sorter: (a, b) => a.membercount - b.membercount,
        },
        {
            title: "Бүртгэлтэй эмэгтэй гишүүдийн тоо",
            dataIndex: "femalecount",
            ...getColumnSearchProps("femalecount"),
            sorter: (a, b) => a.femalecount - b.femalecount,
        },
        {
            title: "Бүртгэлтэй хөгжлийн бэрхшээлтэй  гишүүдийн тоо",
            dataIndex: "disabledcount",
            ...getColumnSearchProps("disabledcount"),
            sorter: (a, b) => a.disabledcount - b.disabledcount,
        },
        {
            title: "Бүртгэлтэй 12 - 18 насны бүлгийн гишүүдийн тоо",
            dataIndex: "childcount",
            ...getColumnSearchProps("childcount"),
            sorter: (a, b) => a.childcount - b.childcount,
        },
        {
            title: "Тухайн бүлгээс гарсан гишүүдийн тоо",
            dataIndex: "leftcount",
            ...getColumnSearchProps("leftcount"),
            sorter: (a, b) => a.leftcount - b.leftcount,
        },
        {
            title: "Цикл эхлэх үеийн хөрөнгө",
            dataIndex: "cycleassets",
            ...getColumnSearchProps("cycleassets"),
            sorter: (a, b) => a.cycleassets - b.cycleassets,
        }
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDeleteConfirm = () => {
        confirm({
            title: "Устгах уу?",
            icon: <ExclamationCircleFilled />,
            //content: 'Some descriptions',
            okText: "Тийм",
            okType: "danger",
            cancelText: "Үгүй",
            onOk() {
                onDelete();
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    };

    const onDelete = async () => {
        await api
            .delete(
                `/api/record/coach/delete_householdgroupcycle?id=${formdata.getFieldValue("id")}`
            )
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const onFinish = async (values) => {


        let fdata = formdata.getFieldsValue();
        fdata.cycledate = fdata.cycledate.format('YYYY.MM.DD');
        await api
            .post(`/api/record/coach/set_householdgroupcycle`, fdata)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const getFormData = async (id) => {
        await api.get(`/api/record/coach/get_householdgroupcycle?id=${id}`).then((res) => {
            if (res?.status === 200 && res?.data?.rettype === 0) {
                let fdata = res?.data?.retdata[0];
                fdata.cycledate = dayjs(fdata.cycledate, 'YYYY.MM.DD');
                formdata.setFieldsValue(fdata);
                showModal();
            }
        });
    };

    const newFormData = async () => {
        formdata.setFieldsValue(
            {
                id: 0,
                householdgroupid: null,
                cycleno: null,
                cycledate: null,
                cyclestatus: null,
                coachname: null,
                membercount: null,
                femalecount: null,
                disabledcount: null,
                childcount: null,
                leftcount: null,
                cycleassets: null,
            });
        showModal();
    };


    return (
        <div>
            <Button
                style={{ marginBottom: 16 }}
                icon={<PlusOutlined />}
                type="primary"
                hidden={userinfo.roleid === "4"}
                onClick={(e) => newFormData()}
            >
                {`${formtitle} нэмэх`}
            </Button>

            <Table
                size="small"
                title={() => (
                    <>
                        <Tag icon={<UserOutlined />} color="magenta">
                            {formtitle} <b>{exceldata.length}</b> харагдаж байна.
                        </Tag>

                        <CSVLink data={exceldata} filename={formtitle + ".csv"}>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                size="small"
                            >
                                Татах
                            </Button>
                        </CSVLink>
                    </>
                )}
                bordered
                loading={loading}
                columns={gridcolumns}
                dataSource={griddata}
                onRow={tableOnRow}
                pagination={true}
                rowKey={(record) => record.id}
            ></Table>

            <Drawer
                forceRender
                title={formtitle}
                width={720}
                onClose={handleCancel}
                open={isModalOpen}
                bodyStyle={{ paddingBottom: 80, }}
                extra={
                    <Space>
                        <Button
                            key="delete"
                            danger
                            onClick={showDeleteConfirm}
                            hidden={formdata.getFieldValue("id") === 0 || userinfo.roleid === "4"}
                        >
                            Устгах
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Болих
                        </Button>
                        <Button
                            key="save"
                            type="primary"
                            onClick={() => { formdata.submit() }}
                            hidden={userinfo.roleid === "4"}
                        >
                            Хадгалах
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={formdata}
                    onFinish={onFinish}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    labelAlign="left"
                    labelWrap
                >
                    <Form.Item name="id" label="Дугаар" hidden={true}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="householdgroupid" label="Дундын хадгаламжийн бүлгийн нэр" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <Select style={{ width: "100%" }}>
                            {householdgroup?.map((t, i) => (
                                <Select.Option key={i} value={t.id}>
                                    {t.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="cycleno" label="Бүлгийн циклийн дугаар" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item name="cycledate" label="Тухайн циклийн хадгаламж хийсэн анхны өдөр" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <DatePicker style={{ width: "100%" }} placeholder="Өдөр сонгох" />
                    </Form.Item>

                    <Form.Item name="cyclestatus" label="Бүлгийн статус" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <Select
                            style={{ width: "100%" }}
                            options={statuslist}
                        />
                    </Form.Item>

                    <Form.Item name="coachname" label="Бүлгийг хянагч чиглүүлэгч коучийн нэр" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <Input />
                    </Form.Item>

                    <Form.Item name="membercount" label="Бүртгэлтэй гишүүдийн тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item name="femalecount" label="Бүртгэлтэй эмэгтэй гишүүдийн тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item name="disabledcount" label="Бүртгэлтэй хөгжлийн бэрхшээлтэй  гишүүдийн тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item name="childcount" label="Бүртгэлтэй 12-18 насны бүлгийн гишүүдийн тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item name="leftcount" label="Тухайн бүлгээс гарсан гишүүдийн тоо" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item name="cycleassets" label="Цикл эхлэх үеийн хөрөнгө" rules={[{ required: true, message: "Утга оруулна уу!" }]} >
                        <InputNumber
                            style={{ width: "100%" }}
                            placeholder="Мөнгөн дүн"
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>

                </Form>
            </Drawer>
        </div>
    );
}

