import React, { useRef, useState, useEffect, useCallback } from "react";
import { api } from "../../system/api";
import useUserInfo from "../../system/useUserInfo";
import {
    Table,
    Tag,
    Space,
    Button,
    Input,
} from "antd";
import {
    Modal,
    Drawer,
    Form,
    DatePicker,
    Select,
    Divider,
} from "antd";
import {
    ExclamationCircleFilled,
    SearchOutlined,
    DownloadOutlined,
    UserOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
const { confirm } = Modal;

export default function Contact() {
    const { userinfo } = useUserInfo();
    const [griddata, setGridData] = useState();
    const [exceldata, setexceldata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formdata] = Form.useForm();
    const [mediatedservicetype, setmediatedservicetype] = useState([]);
    const [intermediaryorganization, setintermediaryorganization] = useState([]);
    const [proxyservice, setproxyservice] = useState([]);
    const [proxyservicecopy, setproxyservicecopy] = useState([]);
    const [household, sethousehold] = useState([]);

    const fetchData = useCallback(() => {
        setLoading(true);
        let coachid = (isNaN(userinfo.coachid) ? 0 : userinfo.coachid) * 1;
        let districtid = (isNaN(userinfo.districtid) ? 0 : userinfo.districtid) * 1;
        api
            .get(`/api/record/coach/get_mediatedactivity_list?coachid=${coachid}&districtid=${districtid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let tdata = res?.data?.retdata.filter(row => row.isactive === true);
                    setGridData(tdata);
                    let list = [];
                    for (var i = 0; i < tdata.length; i++) {
                        let b = tdata[i];
                        let item = {};

                        item["Коуч"] = b.coachid;
                        item["Огноо"] = b.mediateddate;
                        item["Өрхийн дугаар"] = b.householdid;
                        item["Дүүрэг"] = b.districtname;
                        item["Хороо"] = b.section;
                        item["Үйлчилгээний төрөл "] = b.mediatedservicetype;
                        item["Үйлчилгээний нэр"] = b.proxyservice;
                        item["Үйлчилгээ үзүүлсэн байгууллага / ажилтан"] = b.intermediaryorganization;
                        item["Үйлчилгээ авсан өрхийн гишүүний нэр"] = b.membername;
                        item["Хариусан коучийн нэр"] = b.coachname;

                        list.push(item);
                    }
                    setexceldata(list);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [userinfo]);

    useEffect(() => {

        api.get(`/api/record/base/get_dropdown_item_list?type=mediatedservicetype`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setmediatedservicetype(res?.data?.retdata);
                }
            });
        api.get(`/api/record/base/get_dropdown_item_list?type=intermediaryorganization`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setintermediaryorganization(res?.data?.retdata);
                }
            });
        api.get(`/api/record/base/get_dropdown_item_list?type=proxyservice`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setproxyservice(res?.data?.retdata);
                }
            });
        fetchData();
    }, [fetchData]);


    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const gridcolumns = [
        {
            title: "Коуч",
            dataIndex: "coachid",
            ...getColumnSearchProps("coachid"),
            sorter: (a, b) => a.coachid - b.coachid,
        },
        {
            title: "Огноо",
            dataIndex: "mediateddate",
            ...getColumnSearchProps("mediateddate"),
            sorter: (a, b) => a.mediateddate - b.mediateddate,
        },
        {
            title: "Өрхийн дугаар",
            dataIndex: "householdid",
            ...getColumnSearchProps("householdid"),
            sorter: (a, b) => a.householdid - b.householdid,
        },
        {
            title: "Дүүрэг",
            dataIndex: "districtname",
            ...getColumnSearchProps("districtname"),
            sorter: (a, b) => a.districtname - b.districtname,
        },
        {
            title: "Хороо",
            dataIndex: "section",
            ...getColumnSearchProps("section"),
            sorter: (a, b) => a.section - b.section,
        },
        {
            title: "Үйлчилгээний төрөл ",
            dataIndex: "mediatedservicetype",
            ...getColumnSearchProps("mediatedservicetype"),
            sorter: (a, b) => a.mediatedservicetype - b.mediatedservicetype,
        },
        {
            title: "Үйлчилгээний нэр",
            dataIndex: "proxyservice",
            ...getColumnSearchProps("proxyservice"),
            sorter: (a, b) => a.proxyservice - b.proxyservice,
        },
        {
            title: "Үйлчилгээ үзүүлсэн байгууллага / ажилтан",
            dataIndex: "intermediaryorganization",
            ...getColumnSearchProps("intermediaryorganization"),
            sorter: (a, b) => a.intermediaryorganization - b.intermediaryorganization,
        },
        {
            title: "Үйлчилгээ авсан өрхийн гишүүний нэр",
            dataIndex: "membername",
            ...getColumnSearchProps("membername"),
            sorter: (a, b) => a.membername - b.membername,
        },
        {
            title: "Хариусан коучийн нэр",
            dataIndex: "coachname",
            ...getColumnSearchProps("coachname"),
            sorter: (a, b) => a.coachname - b.coachname,
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDeleteConfirm = () => {
        confirm({
            title: "Устгах уу?",
            icon: <ExclamationCircleFilled />,
            //content: 'Some descriptions',
            okText: "Тийм",
            okType: "danger",
            cancelText: "Үгүй",
            onOk() {
                onDelete();
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    };

    const onDelete = async () => {
        await api
            .delete(`/api/record/coach/delete_mediatedactivity?id=${formdata.getFieldValue("entryid")}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const onFinish = async (values) => {
        let fdata = formdata.getFieldsValue();
        fdata.mediateddate = fdata.mediateddate.format("YYYY.MM.DD HH:mm:ss");
        await api
            .post(`/api/record/coach/set_mediatedactivity`, fdata)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const getFormData = async (entryid) => {
        await api
            .get(`/api/record/coach/get_mediatedactivity?id=${entryid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let fdata = res?.data?.retdata[0];
                    fdata.mediateddate = dayjs(fdata.mediateddate, "YYYY.MM.DD HH:mm:ss");
                    formdata.setFieldsValue(fdata);
                    showModal();
                }
            });
    };

    const tableOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                api.get(`/api/record/households/get_householdmember_list?householdid=${record.householdid}`)
                    .then((res) => {
                        if (res?.status === 200 && res?.data?.rettype === 0) {
                            sethousehold(res?.data?.retdata);
                        }
                    });

                setproxyservicecopy(proxyservice?.filter(row => row?.mediatedservicetypeid === record.mediatedservicetypeid));

                getFormData(record.entryid);
            },
        };
    };

    return (
        <div>

            <Table
                size="small"
                bordered
                title={() => (
                    <>
                        <Tag icon={<UserOutlined />} color="magenta">
                            Холбон зуучлалын мэдээлэл <b>{exceldata.length}</b> харагдаж байна.
                        </Tag>

                        <CSVLink data={exceldata} filename={"Холбон жагсаалт.csv"}>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                size="small"
                            >
                                Татах
                            </Button>
                        </CSVLink>
                    </>
                )}
                onChange={(pagination, filters, sorter, extra) =>
                    setexceldata(extra.currentDataSource)
                }
                loading={loading}
                columns={gridcolumns}
                dataSource={griddata}
                onRow={tableOnRow}
                pagination={true}
                rowKey={(record) => record.entryid}
            ></Table>
            <Drawer
                forceRender
                title="Холбон зуучилсан үйл ажиллагааны мэдээлэл нэмэх"
                open={isModalOpen}
                width={720}
                onClose={handleCancel}
                centered
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button
                            key="delete"
                            danger
                            onClick={showDeleteConfirm}
                            hidden={formdata.getFieldValue("entryid") === 0 || userinfo.roleid === "4"}
                        >
                            Устгах
                        </Button>
                        <Button key="cancel" onClick={handleCancel}>
                            Болих
                        </Button>
                        <Button
                            key="save"
                            type="primary"
                            onClick={() => { formdata.submit() }}
                            hidden={userinfo.roleid === "4"}
                        >
                            Хадгалах
                        </Button>
                    </Space>
                }
            >
                <Divider />
                <Form
                    form={formdata}
                    onFinish={onFinish}
                    labelCol={{ span: 9 }}
                    wrapperCol={{ span: 14 }}
                    labelAlign="left"
                    labelWrap
                    onFieldsChange={(changedFields, allFields) => {
                        if (changedFields.length === 1) {
                            if (changedFields[0]?.name[0] === 'mediatedservicetypeid') {
                                formdata.setFieldValue('proxyserviceid', null);
                                setproxyservicecopy(proxyservice?.filter(row => row?.mediatedservicetypeid === formdata?.getFieldValue("mediatedservicetypeid")));
                            }
                        }
                    }}
                >
                    <Form.Item name="entryid" hidden={true} />
                    <Form.Item name="householdid" hidden={true} />
                    <Form.Item name="mediatedservicetypeid" label="Үйлчилгээний төрөл" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: '100%' }}>
                            {mediatedservicetype?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="proxyserviceid" label="Үйлчилгээний нэр" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: '100%' }}>
                            {proxyservicecopy?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="intermediaryorganizationid" label="Үйлчилгээ үзүүлсэн байгууллага / ажилтан " rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: '100%' }}>
                            {intermediaryorganization?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="mediateddate" label="Үйлчилгээ авсан огноо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <DatePicker style={{ width: "100%" }} placeholder="Өдөр сонгох" />
                    </Form.Item>

                    <Form.Item name="memberid" label="Үйлчилгээ авсан өрхийн гишүүний нэр" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                        <Select style={{ width: "100%" }}>
                            {household?.map((t, i) => (<Select.Option key={i} value={t.memberid}>{t.name}</Select.Option>))}
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}
