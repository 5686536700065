import React, { useRef, useState, useEffect, useCallback } from "react";
import { api } from "../../system/api";
import useUserInfo from "../../system/useUserInfo";
import {
    Divider,
    DatePicker,
    Switch,
    Drawer,
    Modal,
    Form,
    Table,
    Space,
    Button,
    AutoComplete,
    Select,
    Input,
    Tag
} from "antd";
import { SearchOutlined, UserOutlined, DownloadOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";
import dayjs from 'dayjs';
const { confirm } = Modal;

const options = [
    {
        value: 'Өрхийн тэргүүн эзгүй байсан',
    },
    {
        value: 'Гэр эзгүй байсан',
    },
];

export default function Visit() {
    const { userinfo } = useUserInfo();
    const [griddata, setGridData] = useState();
    const [exceldata, setexceldata] = useState([]);
    const [relationship, setrelationship] = useState([]);
    const [basicneeds, setbasicneeds] = useState([]);
    const [coachlist, setcoachlist] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formdata] = Form.useForm();

    const fetchData = useCallback(() => {
        setLoading(true);
        let coachid = (isNaN(userinfo.coachid) ? 0 : userinfo.coachid) * 1;
        let districtid = (isNaN(userinfo.districtid) ? 0 : userinfo.districtid) * 1;
        api
            .get(`/api/record/coach/get_householdvisit_list?coachid=${coachid}&districtid=${districtid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let tdata = res?.data?.retdata.filter(row => row.isactive === true);
                    setGridData(tdata);
                    let list = [];
                    for (var i = 0; i < tdata.length; i++) {
                        let b = tdata[i];
                        let item = {};
                        item["Коуч"] = b.coachid;
                        item["Айлчилсан огноо"] = b.visitdate;
                        item["Өрхийн дугаар"] = b.householdid;
                        item["Дүүрэг"] = b.districtname;
                        item["Хороо"] = b.section;
                        item["Айлчлалаар уулзсан өрхийн гишүүн"] = b.membername;
                        item["Өрхийн айлчлалаар хэлэлцсэн асуудал"] = b.note;
                        item["Шийдвэрлэсэн байдал / авах арга хэмжээ"] = b.decisionandaction;
                        item["Өрхийн үндсэн хэрэгцээ"] = b.basicneedsname;
                        item["Үндсэн хэрэгцээний тайлбар"] = b.basicneedsnote;
                        item["Орлого; зарлагын бүртгэлээ тогтмол хөтөлсөн"] = b.incomeexpenditurerecord;
                        item["Өрхийн хөгжлийн төлөвлөгөө боловсруулсан эсэх"] = b.developmentplan;
                        item["Айлчилсан хүний нэр"] = b.coachname;

                        list.push(item);
                    }

                    setexceldata(list);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [userinfo]);

    useEffect(() => {

        api.get(`/api/record/coach/get_coach_list`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setcoachlist(res?.data?.retdata);
                }
            });
        api.get(`/api/record/base/get_dropdown_item_list?type=basicneeds`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setbasicneeds(res?.data?.retdata);
                }
            });
        fetchData();
    }, [fetchData]);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const gridcolumns = [
        {
            title: "Коуч",
            dataIndex: "coachid",
            ...getColumnSearchProps("coachid"),
            sorter: (a, b) => a.coachid - b.coachid,
        },
        {
            title: "Айлчилсан огноо",
            dataIndex: "visitdate",
            ...getColumnSearchProps("visitdate"),
            sorter: (a, b) => a.visitdate - b.visitdate,
        },
        {
            title: "Өрхийн дугаар",
            dataIndex: "householdid",
            ...getColumnSearchProps("householdid"),
            sorter: (a, b) => a.householdid - b.householdid,
        },
        {
            title: "Дүүрэг",
            dataIndex: "districtname",
            ...getColumnSearchProps("districtname"),
            sorter: (a, b) => a.districtname - b.districtname,
        },
        {
            title: "Хороо",
            dataIndex: "section",
            ...getColumnSearchProps("section"),
            sorter: (a, b) => a.section - b.section,
        },
        {
            title: "Айлчлалаар уулзсан өрхийн гишүүн",
            dataIndex: "membername",
            ...getColumnSearchProps("membername"),
            sorter: (a, b) => a.membername - b.membername,
        },
        {
            title: "Өрхийн айлчлалаар хэлэлцсэн асуудал",
            dataIndex: "note",
            ...getColumnSearchProps("note"),
            sorter: (a, b) => a.note - b.note,
        },
        {
            title: "Шийдвэрлэсэн байдал / авах арга хэмжээ",
            dataIndex: "decisionandaction",
            ...getColumnSearchProps("decisionandaction"),
            sorter: (a, b) => a.decisionandaction - b.decisionandaction,
        },
        {
            title: "Өрхийн үндсэн хэрэгцээ",
            dataIndex: "basicneedsname",
            ...getColumnSearchProps("basicneedsname"),
            sorter: (a, b) => a.basicneedsname - b.basicneedsname,
        },
        {
            title: "Үндсэн хэрэгцээний тайлбар",
            dataIndex: "basicneedsnote",
            ...getColumnSearchProps("basicneedsnote"),
            sorter: (a, b) => a.basicneedsnote - b.basicneedsnote,
        },
        {
            title: "Орлого, зарлагын бүртгэлээ тогтмол хөтөлсөн",
            dataIndex: "incomeexpenditurerecord",
            filters: [{ text: "Тийм", value: "Тийм" }, { text: "Үгүй", value: "Үгүй" }, { text: "Хоосон", value: "Хоосон" }],
            onFilter: (value, record) => record.incomeexpenditurerecord.indexOf(value) === 0,
            sorter: (a, b) => a.incomeexpenditurerecord - b.incomeexpenditurerecord,
        },
        {
            title: "Өрхийн хөгжлийн төлөвлөгөө боловсруулсан эсэх",
            dataIndex: "developmentplan",
            filters: [{ text: "Тийм", value: "Тийм" }, { text: "Үгүй", value: "Үгүй" }, { text: "Хоосон", value: "Хоосон" }],
            onFilter: (value, record) => record.developmentplan.indexOf(value) === 0,
            sorter: (a, b) => a.developmentplan - b.developmentplan,
        },
        {
            title: "Айлчилсан хүний нэр",
            dataIndex: "coachname",
            ...getColumnSearchProps("coachname"),
            sorter: (a, b) => a.coachname - b.coachname,
        },
    ];

    const tableOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                getFormData(record.visitid);
            },
        };
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDeleteConfirm = () => {
        confirm({
            title: "Устгах уу?",
            icon: <ExclamationCircleFilled />,
            //content: 'Some descriptions',
            okText: "Тийм",
            okType: "danger",
            cancelText: "Үгүй",
            onOk() {
                onDelete();
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    };

    const onDelete = async () => {
        await api
            .delete(
                `/api/record/coach/delete_householdvisit?id=${formdata.getFieldValue(
                    "visitid"
                )}`
            )
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const onFinish = async (values) => {
        let fdata = formdata.getFieldsValue();
        fdata.visitdate = fdata.visitdate.format('YYYY.MM.DD HH:mm:ss');
        await api
            .post(`/api/record/coach/set_householdvisit`, fdata)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const getFormData = async (visitid) => {
        await api
            .get(`/api/record/coach/get_householdvisit?id=${visitid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let fdata = res?.data?.retdata[0];
                    fdata.visitdate = dayjs(fdata.visitdate, 'YYYY.MM.DD HH:mm:ss');
                    formdata.setFieldsValue(fdata);

                    api.get(`/api/record/households/get_householdmember_list?householdid=${fdata.householdid}`)
                        .then((res) => {
                            if (res?.status === 200 && res?.data?.rettype === 0) {
                                setrelationship(res?.data?.retdata);
                            }
                        });

                    showModal();
                }
            });
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current >= dayjs().endOf('day');
    };
    return (
        <>
            <div className="row">
                <div className="col-md-12">

                    <Table
                        size="small"
                        bordered
                        title={() => (
                            <>
                                <Tag icon={<UserOutlined />} color="magenta">
                                    Айлчлалын мэдээлэл <b>{exceldata.length}</b> харагдаж байна.
                                </Tag>

                                <CSVLink data={exceldata} filename={"Айлчлалын жагсаалт.csv"}>
                                    <Button type="primary" icon={<DownloadOutlined />} size="small">
                                        Татах
                                    </Button>
                                </CSVLink>
                            </>
                        )}
                        onChange={(pagination, filters, sorter, extra) =>
                            setexceldata(extra.currentDataSource)
                        }
                        loading={loading}
                        columns={gridcolumns}
                        dataSource={griddata}
                        pagination={true}
                        onRow={tableOnRow}
                        rowKey={(record) => record.visitid}
                    ></Table>
                    <Drawer
                        forceRender
                        title="Өрхийн айлчлалын мэдээлэл нэмэх"
                        open={isModalOpen}
                        width={720}
                        onClose={handleCancel}
                        centered
                        bodyStyle={{ paddingBottom: 80, }}
                        extra={
                            <Space>
                                <Button
                                    key="delete"
                                    danger
                                    onClick={showDeleteConfirm}
                                    hidden={formdata.getFieldValue("visitid") === 0 || userinfo.roleid === "4"}
                                >
                                    Устгах
                                </Button>
                                <Button key="cancel" onClick={handleCancel}>
                                    Болих
                                </Button>
                                <Button
                                    key="save"
                                    type="primary"
                                    onClick={() => { formdata.submit() }}
                                    hidden={userinfo.roleid === "4"}
                                >
                                    Хадгалах
                                </Button>
                            </Space>
                        }
                    >
                        <Divider />
                        <Form
                            form={formdata}
                            onFinish={onFinish}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            labelAlign="left"
                            labelWrap
                        >
                            <Form.Item name="visitid" hidden={true} />
                            <Form.Item name="householdid" hidden={true} />
                            <Form.Item name="coachid" label="Айлчилсан хүний нэр"  >
                                <Select style={{ width: "100%" }}>
                                    {coachlist?.map((t, i) => (
                                        <Select.Option key={i} value={t.coachid}>
                                            {t.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="visitdate" label="Айлчилсан огноо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                <DatePicker
                                    disabledDate={disabledDate}
                                    style={{ width: "100%" }}
                                    placeholder="Өдөр сонгох" />
                            </Form.Item>
                            <Form.Item name="basicneedsid" label="Өрхийн үндсэн хэрэгцээ" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}>
                                    {basicneeds?.map((t, i) => (
                                        <Select.Option key={i} value={t.id}>
                                            {t.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="basicneedsnote" label="Үндсэн хэрэгцээний тайлбар" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item
                                name="incomeexpenditurerecord"
                                label="Орлого, зарлагын бүртгэлээ тогтмол хөтөлсөн эсэх?"
                                valuePropName="checked"
                            >
                                <Switch checkedChildren="Тийм" unCheckedChildren="Үгүй" style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="developmentplan"
                                label="Өрхийн хөгжлийн төлөвлөгөө боловсруулсан эсэх?"
                                valuePropName="checked"
                            >
                                <Switch checkedChildren="Тийм" unCheckedChildren="Үгүй" style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item name="memberid" label="Айлчлалаар уулзсан өрхийн гишүүн" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                <Select style={{ width: "100%" }}>
                                    {relationship?.map((t, i) => (
                                        <Select.Option key={i} value={t.memberid}>
                                            {t.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="note" label="Өрхийн айлчлалаар хэлэлцсэн асуудал" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                <AutoComplete
                                    style={{
                                        width: "100%"
                                    }}
                                    options={options}
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                >
                                    <Input.TextArea />

                                </AutoComplete>
                            </Form.Item>
                            <Form.Item name="decisionandaction" label="Шийдвэрлэсэн байдал / авах арга хэмжээ" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                <Input.TextArea />
                            </Form.Item>
                        </Form>
                    </Drawer>
                </div>
            </div>

        </>
    );
}
