import React, { useRef, useState, useEffect, useCallback } from "react";
import { api } from "../../system/api";
import useUserInfo from "../../system/useUserInfo";
import {
    Tabs,
    Table,
    Tag,
    Space,
    Button,
    Input,
    Typography,
} from "antd";
import {
    Modal,
    Drawer,
    Form,
    DatePicker,
    Divider,
    Select,
    InputNumber,
} from "antd";
import {
    ExclamationCircleFilled,
    SearchOutlined,
    DownloadOutlined,
    UserOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
const { confirm } = Modal;
const { Text } = Typography;

export default function Investment() {
    const { userinfo } = useUserInfo();
    const [griddata, setGridData] = useState();
    const [exceldata, setexceldata] = useState([]);
    const [griddata2, setGridData2] = useState();
    const [exceldata2, setexceldata2] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formdata] = Form.useForm();
    const [formdata2] = Form.useForm();
    const [assetreceivedtype, setassetreceivedtype] = useState([]);
    const [assetreceived, setassetreceived] = useState([]);
    const [supportreceivedtype, setsupportreceivedtype] = useState([]);
    const [sponsoringorganization, setsponsoringorganization] = useState([]);

    const fetchData = useCallback(() => {
        setLoading(true);
        let coachid = (isNaN(userinfo.coachid) ? 0 : userinfo.coachid) * 1;
        let districtid = (isNaN(userinfo.districtid) ? 0 : userinfo.districtid) * 1;
        api
            .get(`/api/record/coach/get_othersupport_list?coachid=${coachid}&districtid=${districtid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let tdata = res?.data?.retdata.filter(row => row.isactive === true);
                    setGridData2(tdata);
                    let list = [];
                    for (var i = 0; i < tdata.length; i++) {
                        let b = tdata[i];
                        let item = {};

                        item["Коуч"] = b.coachid;
                        item["Өрхийн дугаар"] = b.householdid;
                        item["Дүүрэг"] = b.districtname;
                        item["Хороо"] = b.section;
                        item["Гол оролцогч"] = b.householdname;
                        item["Тусламж, дэмжлэг хүлээн авсан огноо"] = b.supportdate;
                        item["Хүлээн авсан дэмжлэгийн төрөл"] = b.supportreceivedtype;
                        item["Хүлээн авсан тусламж дэмжлэг"] = b.name;
                        item["Тоо ширхэг"] = b.quantity;
                        item["Нэгжийн үнэ"] = b.funitprice;
                        item["Нийт үнэ"] = b.ftotalprice;
                        item["Дэмжлэг олгосон байгууллагын нэр"] = b.sponsoringorganization;

                        list.push(item);
                    }
                    setexceldata2(list);
                }
            });

        api
            .get(`/api/record/coach/get_investment_list?coachid=${coachid}&districtid=${districtid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let tdata = res?.data?.retdata.filter(row => row.isactive === true);
                    setGridData(tdata);
                    let list = [];
                    for (var i = 0; i < tdata.length; i++) {
                        let b = tdata[i];
                        let item = {};
                        item["Коуч"] = b.coachid;
                        item["Хөрөнгө хүлээн авсан огноо"] = b.investmentdate;
                        item["Өрхийн дугаар"] = b.householdid;
                        item["Дүүрэг"] = b.districtname;
                        item["Хороо"] = b.section;
                        item["Гол оролцогч"] = b.householdname;
                        item["Хүлээн авсан хөрөнгийн төрөл"] = b.assetreceivedtype;
                        item["Хүлээн авсан хөрөнгийн нэр"] = b.assetreceived;
                        item["Тоо ширхэг"] = b.quantity;
                        item["Нэгжийн үнэ"] = b.funitprice;
                        item["Нийт үнэ"] = b.ftotalprice;
                        item["Тайлбар (марк; дугаар)"] = b.note;

                        list.push(item);
                    }
                    setexceldata(list);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [userinfo]);

    useEffect(() => {
        api.get(`/api/record/base/get_dropdown_item_list?type=supportreceivedtype`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setsupportreceivedtype(res?.data?.retdata);
                }
            });
        api.get(`/api/record/base/get_dropdown_item_list?type=sponsoringorganization`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setsponsoringorganization(res?.data?.retdata);
                }
            });
        api.get(`/api/record/base/get_dropdown_item_list?type=assetreceivedtype`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setassetreceivedtype(res?.data?.retdata);
                }
            });
        api.get(`/api/record/base/get_dropdown_item_list?type=assetreceived`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setassetreceived(res?.data?.retdata);
                }
            });
        fetchData();
    }, [fetchData]);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const gridcolumns = [
        {
            title: "Коуч",
            dataIndex: "coachid",
            ...getColumnSearchProps("coachid"),
            sorter: (a, b) => a.coachid - b.coachid,
        },
        {
            title: "Хөрөнгө хүлээн авсан огноо",
            dataIndex: "investmentdate",
            ...getColumnSearchProps("investmentdate"),
            sorter: (a, b) => a.investmentdate - b.investmentdate,
        },
        {
            title: "Өрхийн дугаар",
            dataIndex: "householdid",
            ...getColumnSearchProps("householdid"),
            sorter: (a, b) => a.householdid - b.householdid,
        },
        {
            title: "Дүүрэг",
            dataIndex: "districtname",
            ...getColumnSearchProps("districtname"),
            sorter: (a, b) => a.districtname - b.districtname,
        },
        {
            title: "Хороо",
            dataIndex: "section",
            ...getColumnSearchProps("section"),
            sorter: (a, b) => a.section - b.section,
        },
        {
            title: "Гол оролцогч",
            dataIndex: "householdname",
            ...getColumnSearchProps("householdname"),
            sorter: (a, b) => a.householdname - b.householdname,
        },
        {
            title: "Хүлээн авсан хөрөнгийн төрөл",
            dataIndex: "assetreceivedtype",
            ...getColumnSearchProps("assetreceivedtype"),
            sorter: (a, b) => a.assetreceivedtype - b.assetreceivedtype,
        },
        {
            title: "Хүлээн авсан хөрөнгийн нэр",
            dataIndex: "assetreceived",
            ...getColumnSearchProps("assetreceived"),
            sorter: (a, b) => a.assetreceived - b.assetreceived,
        },
        {
            title: "Тоо ширхэг",
            dataIndex: "quantity",
            align: "right",
            ...getColumnSearchProps("quantity"),
            sorter: (a, b) => a.quantity - b.quantity,
        },
        {
            title: "Нэгжийн үнэ",
            dataIndex: "funitprice",
            align: "right",
            ...getColumnSearchProps("funitprice"),
            sorter: (a, b) => a.funitprice - b.funitprice,
        },
        {
            title: "Нийт үнэ",
            dataIndex: "ftotalprice",
            align: "right",
            ...getColumnSearchProps("ftotalprice"),
            sorter: (a, b) => a.ftotalprice - b.ftotalprice,
        },
        {
            title: "Тайлбар (марк, дугаар)",
            dataIndex: "note",
            ...getColumnSearchProps("note"),
            sorter: (a, b) => a.note - b.note,
        },
    ];

    const gridcolumns2 = [
        {
            title: "Коуч",
            dataIndex: "coachid",
            ...getColumnSearchProps("coachid"),
            sorter: (a, b) => a.coachid - b.coachid,
        },
        {
            title: "Өрхийн дугаар",
            dataIndex: "householdid",
            ...getColumnSearchProps("householdid"),
            sorter: (a, b) => a.householdid - b.householdid,
        },
        {
            title: "Дүүрэг",
            dataIndex: "districtname",
            ...getColumnSearchProps("districtname"),
            sorter: (a, b) => a.districtname - b.districtname,
        },
        {
            title: "Хороо",
            dataIndex: "section",
            ...getColumnSearchProps("section"),
            sorter: (a, b) => a.section - b.section,
        },
        {
            title: "Гол оролцогч",
            dataIndex: "householdname",
            ...getColumnSearchProps("householdname"),
            sorter: (a, b) => a.householdname - b.householdname,
        },
        {
            title: "Тусламж, дэмжлэг хүлээн авсан огноо",
            dataIndex: "supportdate",
            ...getColumnSearchProps("supportdate"),
            sorter: (a, b) => a.supportdate - b.supportdate,
        },
        {
            title: "Хүлээн авсан дэмжлэгийн төрөл",
            dataIndex: "supportreceivedtype",
            ...getColumnSearchProps("supportreceivedtype"),
            sorter: (a, b) => a.supportreceivedtype - b.supportreceivedtype,
        },
        {
            title: "Хүлээн авсан тусламж дэмжлэг",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: "Тоо ширхэг",
            dataIndex: "quantity",
            ...getColumnSearchProps("quantity"),
            align: 'right',
            sorter: (a, b) => a.quantity - b.quantity,
        },
        {
            title: "Нэгжийн үнэ",
            dataIndex: "funitprice",
            ...getColumnSearchProps("funitprice"),
            align: 'right',
            sorter: (a, b) => a.funitprice - b.funitprice,
        },
        {
            title: "Нийт үнэ",
            dataIndex: "ftotalprice",
            ...getColumnSearchProps("ftotalprice"),
            align: 'right',
            sorter: (a, b) => a.ftotalprice - b.ftotalprice,
        },
        {
            title: "Дэмжлэг олгосон байгууллагын нэр",
            dataIndex: "sponsoringorganization",
            ...getColumnSearchProps("sponsoringorganization"),
            sorter: (a, b) => a.sponsoringorganization - b.sponsoringorganization,
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDeleteConfirm = () => {
        confirm({
            title: "Устгах уу?",
            icon: <ExclamationCircleFilled />,
            //content: 'Some descriptions',
            okText: "Тийм",
            okType: "danger",
            cancelText: "Үгүй",
            onOk() {
                onDelete();
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    };

    const onDelete = async () => {
        await api
            .delete(
                `/api/record/coach/delete_investment?id=${formdata.getFieldValue(
                    "entryid"
                )}`
            )
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const onFinish = async (values) => {
        let fdata = formdata.getFieldsValue();
        fdata.investmentdate = fdata.investmentdate.format("YYYY.MM.DD HH:mm:ss");
        await api.post(`/api/record/coach/set_investment`, fdata).then((res) => {
            if (res?.status === 200 && res?.data?.rettype === 0) {
                setIsModalOpen(false);
                fetchData();
            }
        });
    };

    const getFormData = async (entryid) => {
        await api
            .get(`/api/record/coach/get_investment?id=${entryid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let fdata = res?.data?.retdata[0];
                    fdata.investmentdate = dayjs(
                        fdata.investmentdate,
                        "YYYY.MM.DD HH:mm:ss"
                    );
                    formdata.setFieldsValue(fdata);
                    showModal();
                }
            });
    };

    const tableOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {

                getFormData(record.entryid);
            },
        };
    };


    const [isModalOpen2, setIsModalOpen2] = useState(false);

    const showModal2 = () => {
        setIsModalOpen2(true);
    };

    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };

    const showDeleteConfirm2 = () => {
        confirm({
            title: "Устгах уу?",
            icon: <ExclamationCircleFilled />,
            //content: 'Some descriptions',
            okText: "Тийм",
            okType: "danger",
            cancelText: "Үгүй",
            onOk() {
                onDelete2();
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    };

    const onDelete2 = async () => {
        await api
            .delete(
                `/api/record/coach/delete_othersupport?id=${formdata2.getFieldValue(
                    "entryid"
                )}`
            )
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    setIsModalOpen(false);
                    fetchData();
                }
            });
    };

    const onFinish2 = async (values) => {
        let fdata = formdata2.getFieldsValue();
        fdata.supportdate = fdata.supportdate.format("YYYY.MM.DD HH:mm:ss");
        await api.post(`/api/record/coach/set_othersupport`, fdata).then((res) => {
            if (res?.status === 200 && res?.data?.rettype === 0) {
                setIsModalOpen(false);
                fetchData();
            }
        });
    };

    const getFormData2 = async (entryid) => {
        await api
            .get(`/api/record/coach/get_othersupport?id=${entryid}`)
            .then((res) => {
                if (res?.status === 200 && res?.data?.rettype === 0) {
                    let fdata = res?.data?.retdata[0];
                    fdata.supportdate = dayjs(
                        fdata.supportdate,
                        "YYYY.MM.DD HH:mm:ss"
                    );
                    formdata2.setFieldsValue(fdata);
                    showModal2();
                }
            });
    };

    const tableOnRow2 = (record, rowIndex) => {
        return {
            onClick: (event) => {

                getFormData2(record.entryid);
            },
        };
    };

    return (
        <div>
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        label: `Хөрөнгө оруулалт, тусламж`,
                        key: "5",
                        children: (
                            <div>

                                <Table
                                    size="small"
                                    bordered
                                    title={() => (
                                        <>
                                            <Tag icon={<UserOutlined />} color="magenta">
                                                Хөрөнгийн мэдээлэл <b>{exceldata.length}</b> харагдаж байна.
                                            </Tag>

                                            <CSVLink data={exceldata} filename={"Хөрөнгийн жагсаалт.csv"}>
                                                <Button type="primary" icon={<DownloadOutlined />} size="small">
                                                    Татах
                                                </Button>
                                            </CSVLink>
                                        </>
                                    )}
                                    onChange={(pagination, filters, sorter, extra) =>
                                        setexceldata(extra.currentDataSource)
                                    }
                                    loading={loading}
                                    columns={gridcolumns}
                                    dataSource={griddata}
                                    onRow={tableOnRow}
                                    pagination={true}
                                    rowKey={(record) => record.entryid}
                                    summary={(pageData) => {
                                        let totalamount = 0;
                                        let totalquantity = 0;
                                        pageData.forEach(({ totalprice, quantity }) => {
                                            totalquantity += quantity;
                                            totalamount += totalprice;
                                        });
                                        totalamount = totalamount
                                            .toFixed(2)
                                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                                        return (
                                            <>
                                                <Table.Summary.Row style={{ background: "#fafafa" }}>
                                                    <Table.Summary.Cell index={0}>Нийт:</Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1} />
                                                    <Table.Summary.Cell index={2} />
                                                    <Table.Summary.Cell index={3} />
                                                    <Table.Summary.Cell index={4} />
                                                    <Table.Summary.Cell index={5} />
                                                    <Table.Summary.Cell index={6} align="right">
                                                        <Text>{totalquantity}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={7} />
                                                    <Table.Summary.Cell index={8} align="right">
                                                        <Text>{totalamount}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={9} />
                                                </Table.Summary.Row>
                                            </>
                                        );
                                    }}
                                ></Table>

                                <Drawer
                                    forceRender
                                    title="Хөрөнгө оруулалтын мэдээлэл нэмэх"
                                    open={isModalOpen}
                                    width={720}
                                    onClose={handleCancel}
                                    centered
                                    bodyStyle={{ paddingBottom: 80 }}
                                    extra={
                                        <Space>
                                            <Button
                                                key="delete"
                                                danger
                                                onClick={showDeleteConfirm}
                                                hidden={formdata.getFieldValue("entryid") === 0 || userinfo.roleid === "4"}
                                            >
                                                Устгах
                                            </Button>
                                            <Button key="cancel" onClick={handleCancel}>
                                                Болих
                                            </Button>
                                            <Button
                                                key="save"
                                                type="primary"
                                                onClick={() => { formdata.submit() }}
                                                hidden={userinfo.roleid === "4"}
                                            >
                                                Хадгалах
                                            </Button>
                                        </Space>
                                    }
                                >
                                    <Divider />
                                    <Form
                                        form={formdata}
                                        onFinish={onFinish}
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 14 }}
                                        labelAlign="left"
                                        labelWrap
                                        onFieldsChange={(changedFields, allFields) => {
                                            if (changedFields.length === 1) {
                                                if (changedFields[0]?.name[0] === 'quantity' || changedFields[0]?.name[0] === 'unitprice') {
                                                    formdata.setFieldValue('totalprice', formdata.getFieldValue('quantity') * formdata.getFieldValue('unitprice'));
                                                }
                                            }
                                        }}
                                    >
                                        <Form.Item name="entryid" hidden={true} />
                                        <Form.Item name="householdid" hidden={true} />
                                        <Form.Item name="investmentdate" label="Хөрөнгө хүлээн авсан огноо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <DatePicker style={{ width: "100%" }} placeholder="Сар сонгох" />
                                        </Form.Item>
                                        <Form.Item name="assetreceivedtypeid" label="Хүлээн авсан хөрөнгийн төрөл" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <Select style={{ width: '100%' }}>
                                                {assetreceivedtype?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="assetreceivedid" label="Хүлээн авсан хөрөнгийн нэр" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <Select style={{ width: '100%' }}>
                                                {assetreceived?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="quantity" label="Тоо ширхэг" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <InputNumber
                                                min={0}
                                                placeholder="Тоо ширхэг"
                                                style={{ width: "100%" }}
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                            />
                                        </Form.Item>
                                        <Form.Item name="unitprice" label="Нэгжийн үнэ" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <InputNumber
                                                placeholder="Нэгжийн үнэ"
                                                min={0}
                                                style={{ width: "100%" }}
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                            />
                                        </Form.Item>
                                        <Form.Item name="totalprice" label="Нийт үнэ" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <InputNumber
                                                placeholder="Нийт үнэ"
                                                min={0}
                                                style={{ width: "100%" }}
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                            />
                                        </Form.Item>
                                        <Form.Item name="note" label="Тайлбар (марк, дугаар)" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <Input.TextArea
                                                placeholder="Тайлбар (марк, дугаар)"
                                                style={{ width: "100%" }}
                                            />
                                        </Form.Item>
                                    </Form>
                                </Drawer>
                            </div>
                        ),
                    },
                    {
                        label: `Бусад тусламж, дэмжлэг`,
                        key: "6",

                        children: (
                            <div>

                                <Table
                                    size="small"
                                    bordered
                                    title={() => (
                                        <>
                                            <Tag icon={<UserOutlined />} color="magenta">
                                                Бусад тусламж, дэмжлэгийн мэдээлэл <b>{exceldata2.length}</b> харагдаж байна.
                                            </Tag>

                                            <CSVLink data={exceldata2} filename={"Бусад тусламж, дэмжлэгийн жагсаалт.csv"}>
                                                <Button type="primary" icon={<DownloadOutlined />} size="small">
                                                    Татах
                                                </Button>
                                            </CSVLink>
                                        </>
                                    )}
                                    onChange={(pagination, filters, sorter, extra) =>
                                        setexceldata2(extra.currentDataSource)
                                    }
                                    loading={loading}
                                    columns={gridcolumns2}
                                    dataSource={griddata2}
                                    onRow={tableOnRow2}
                                    pagination={true}
                                    rowKey={(record) => record.entryid}
                                    summary={(pageData) => {
                                        let totalamount = 0;
                                        let totalquantity = 0;
                                        pageData.forEach(({ totalprice, quantity }) => {
                                            totalquantity += quantity;
                                            totalamount += totalprice;
                                        });
                                        totalamount = totalamount
                                            .toFixed(2)
                                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                                        return (
                                            <>
                                                <Table.Summary.Row style={{ background: "#fafafa" }}>
                                                    <Table.Summary.Cell index={0}>Нийт:</Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1} />
                                                    <Table.Summary.Cell index={2} />
                                                    <Table.Summary.Cell index={3} />
                                                    <Table.Summary.Cell index={4} />
                                                    <Table.Summary.Cell index={5} />
                                                    <Table.Summary.Cell index={6} />
                                                    <Table.Summary.Cell index={7} />
                                                    <Table.Summary.Cell index={8} align="right">
                                                        <Text>{totalquantity}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={9} />
                                                    <Table.Summary.Cell index={10} align="right">
                                                        <Text>{totalamount}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={11} />
                                                </Table.Summary.Row>
                                            </>
                                        );
                                    }}
                                ></Table>

                                <Drawer
                                    forceRender
                                    title="Бусад тусламж, дэмжлэг нэмэх"
                                    open={isModalOpen2}
                                    width={720}
                                    onClose={handleCancel2}
                                    centered
                                    bodyStyle={{ paddingBottom: 80 }}
                                    extra={
                                        <Space>
                                            <Button
                                                key="delete"
                                                danger
                                                onClick={showDeleteConfirm2}
                                                hidden={formdata2.getFieldValue("entryid") === 0 || userinfo.roleid === "4"}
                                            >
                                                Устгах
                                            </Button>
                                            <Button key="cancel" onClick={handleCancel2}>
                                                Болих
                                            </Button>
                                            <Button
                                                key="save"
                                                type="primary"
                                                onClick={() => { formdata2.submit() }}
                                                hidden={userinfo.roleid === "4"}
                                            >
                                                Хадгалах
                                            </Button>
                                        </Space>
                                    }
                                >
                                    <Divider />
                                    <Form
                                        form={formdata2}
                                        onFinish={onFinish2}
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 14 }}
                                        labelAlign="left"
                                        labelWrap
                                        onFieldsChange={(changedFields, allFields) => {
                                            if (changedFields.length === 1) {
                                                if (changedFields[0]?.name[0] === 'quantity' || changedFields[0]?.name[0] === 'unitprice') {
                                                    formdata2.setFieldValue('totalprice', formdata2.getFieldValue('quantity') * formdata2.getFieldValue('unitprice'));
                                                }
                                            }
                                        }}
                                    >
                                        <Form.Item name="entryid" hidden={true} />
                                        <Form.Item name="householdid" hidden={true} />
                                        <Form.Item name="supportdate" label="Тусламж, дэмжлэг хүлээн авсан огноо" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <DatePicker style={{ width: "100%" }} placeholder="Өдөр сонгох" />
                                        </Form.Item>
                                        <Form.Item name="supportreceivedtypeid" label="Хүлээн авсан дэмжлэгийн төрөл" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <Select style={{ width: '100%' }}>
                                                {supportreceivedtype?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="name" label="Хүлээн авсан тусламж дэмжлэг" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <Input placeholder="Хүлээн авсан тусламж дэмжлэг" />
                                        </Form.Item>
                                        <Form.Item name="quantity" label="Тоо ширхэг" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <InputNumber
                                                min={0}
                                                placeholder="Тоо ширхэг"
                                                style={{ width: "100%" }}
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                            />
                                        </Form.Item>
                                        <Form.Item name="unitprice" label="Нэгжийн үнэ" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <InputNumber
                                                placeholder="Нэгжийн үнэ"
                                                min={0}
                                                style={{ width: "100%" }}
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                            />
                                        </Form.Item>
                                        <Form.Item name="totalprice" label="Нийт үнэ" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <InputNumber
                                                placeholder="Нийт үнэ"
                                                min={0}
                                                style={{ width: "100%" }}
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                            />
                                        </Form.Item>
                                        <Form.Item name="sponsoringorganizationid" label="Дэмжлэг олгосон байгууллагын нэр" rules={[{ required: true, message: "Утга оруулна уу!" }]}>
                                            <Select style={{ width: '100%' }}>
                                                {sponsoringorganization?.map((t, i) => (<Select.Option key={i} value={t.id}>{t.name}</Select.Option>))}
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </Drawer>
                            </div>
                        ),
                    },
                ]}
            />


        </div>
    );
}
